//DisplayBadges.js
///////////////////////////////
//React & Material
import React from "react";
import { Grid } from "@material-ui/core";



import DisplayBadge from "./DisplayBadge"

function DisplayBadges(props) {

  if(props.badges !== undefined){
    return (
      <Grid container spacing={1}>
        {props.badges.map((badge, index) =>
          <React.Fragment key={index}>
            <DisplayBadge
              name={badge.title}
              icon={badge.icon}
              iconColor={badge.color}
              status={badge.status}
              badge_desc={badge.detail_description}
              badge_val={badge.detail_value}
            />
          </React.Fragment>
        )}
      </Grid>
    );
  }else{
    return(
      <React.Fragment>
        Loading...
      </React.Fragment>
    )
  }
}

export default DisplayBadges;
