//DefaultPage.js
///////////////////////////////
//React & Material
import React from 'react';
import { Switch, Route } from 'react-router-dom'
import {
  withRouter
} from 'react-router-dom'

//Plugins
import styled from 'styled-components';


//Page Imports
import DefaultPage from './Pages/DefaultPage'
import InfoPage from './Pages/InfoPage'
// import PrivacyPage from './Pages/PrivacyPage'
import ContactPage from './Pages/ContactPage'
import ProfilePage from './Pages/ProfilePage'

//Component Imports
import FooterV2 from '../components/Site-Structure/FooterV2';
import Navbar from '../components/Site-Structure/Navbar'
// import CompliancePopUp from "../components/Site-Structure/CompliancePopUp"
import ErrorDisplay from "../components/Site-Structure/ErrorDisplay"

import CompanyProfile from "../components/Profile/CompanyProfile"
// import ProfileHeader from "../components/Profile/ProfileHeader"


//Styled components
const Container = styled.div `
  min-height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const Content = styled.section `
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 50px;
`

function getYear() {
  return new Date().getFullYear();
}

//////////////////////////////
//Component function
function PublicView(props) {
  
  return (
    <Container>
      <Navbar/>
      <Content>
      {/* <CompliancePopUp/> */}
        <Switch>
          <Route exact path='/' component={DefaultPage}/>
          <Route path = '/about' component={InfoPage}/>
          <Route path = '/contact' component={ContactPage}/>
          <Route path = '/profile' component={ProfilePage}/>
          <Route path = '/experience' component={CompanyProfile}/>

          <Route path = "*" component={ErrorDisplay}/>
        </Switch>
      </Content>
      <FooterV2 footertext={`© ${getYear()} Matt Balshaw`}/>
    </Container>
  )
}
export default withRouter(PublicView);
