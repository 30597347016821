//DisplayBadges.js
///////////////////////////////
//React & Material
import React, { useState } from "react";
import { Chip, Grid } from "@material-ui/core";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';


//display single badge
function DisplayBadge(props) {
  //styled component for icon

  //The code looks a bit strange
  //This is intended:
  //We want to display pending if a non-standard status
  //is returned.
  //This ensures that we don't accidentally show verified

  const DisabledOpacity = () => {
    if (props.status === "pending" || props.status === "failed") {
      return 0.5;
    } else if (props.status === "complete") {
      return 1;
    }else {
      return 0.5;
    }
  }

  const DisabledBackground = () => {
    if (props.status === "pending" || props.status === "failed") {
      return "background: repeating-linear-gradient(-55deg,#222,#222 10px,#333 10px,#333 20px);";
    } else if (props.status === "complete") {
      return "";
    }else {
      return "background: repeating-linear-gradient(-55deg,#222,#222 10px,#333 10px,#333 20px);";
    }
  }

  const Icon = styled.span`
    color: ${props.iconColor} !important;
    font-size: 1.5em;
    margin-left: 8px!important;
    width:22px!important;
  `;

  const WaitIcon = styled.span`
    color: #ffb300 !important;
    font-size: 1.3em;
    height:auto!important;
    opacity: 1!important;
    width:22px!important;
  `

  const FailIcon = styled.span`
    color: #ff3d00 !important;
    font-size: 1.3em;
    height:auto!important;
    opacity: 1!important;
    width:22px!important;
  `

  const CustomChip = styled(Chip)`
    border-radius: 2px!important;
    span {
      opacity: ${DisabledOpacity()};
    }
    ${DisabledBackground()};
  `

  const NegDiv = styled.div`
    width: 0!important;
  `

  const RenderDeleteIcon = () => {
    let TheIcon;

    if (props.status === "pending") {
      TheIcon = (
        <WaitIcon
          className={"mdi mdi-progress-alert"}
        >
        </WaitIcon>
      )
    } else if (props.status === "failed") {
      TheIcon = (
        <FailIcon
          className={"mdi mdi-alert-octagon"}
        >
        </FailIcon>
      )
    } else if (props.status === "complete"){
      TheIcon = (
        <NegDiv />
      )
    } else {
      TheIcon = (
        <WaitIcon
          className={"mdi mdi-progress-alert"}
        >
        </WaitIcon>
      )
    }
    return (TheIcon)
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <React.Fragment>
      <Grid item>
        <CustomChip
          onClick={handleClickOpen}
          onDelete={() => { console.log(".") }}
          icon={
            <Icon
              className={"mdi mdi-" + props.icon}
            >
            </Icon>}
          deleteIcon={RenderDeleteIcon()}
          label={props.name}
        />
      </Grid>
      {/* Modal to show in detail of the badge which will be customized to the user */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="simple-dialog-title">{props.name} Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.badge_desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DisplayBadge;