//DisplayError.js
///////////////////////////////
//React & Material
import React from 'react';

//Plugins
import styled from 'styled-components';

import { ReactComponent as InternalError } from './subconnect_construction.svg';
import { ReactComponent as PeopleStatsTicks } from './subconnect_analytics.svg';
import { ReactComponent as LoadingImg } from './subconnect_analytics.svg';

import {
  Typography,
  Paper,
  Button,
} from '@material-ui/core';
//Theme (required for styleguidist workaround)
import { Link } from "react-router-dom";

//Component Imports

//////////////////////////////
//Styled components

const ErrorWrapper = styled.div`
  display: flex;
  width:95vw;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`

const NoAuthImgWrapper = styled(InternalError)`
  max-width: 450px;
`
const ErrorImgWrapper = styled(PeopleStatsTicks)`
  max-width: 450px;
`
const MissingImgWrapper = styled(LoadingImg)`
  max-width: 450px;
`

const Spacer = styled.div `
  width: 90vw;
  height: ${props => props.height}px;
`

const ErrorTextPanel = styled(Paper)`
  padding:10px 40px;
  background-color: #272727 !important;
  max-width: 450px;
`

const GradientButton = styled(Button)`
  background: linear-gradient(45deg, #fc9511 30%, #d22600 90%);
  color: #FFF!important;
`

const NoStyleLink = styled(Link) `
  && {
    text-decoration: none;
    color: white;
    cursor: pointer;
    text-align: center;
  }
`

//////////////////////////////
//Component class
/**
 * Displays an error component
 */
function DisplayError(props) {

  //Use the auth0 cookie to check if we are logged in
  const loggedIn = () =>{
    let authCookie = document.cookie.replace(/(?:(?:^|.*;\s*)auth0.is.authenticated\s*=\s*([^;]*).*$)|^.*$/, "$1");
    if (authCookie){
      return true;
    }else{
      return false;
    }
  }

  const RenderButtonLink = () =>{

    //Always shows either a profile link or a
    //Signup button

    let ButtonLink;

    if (loggedIn()){
      let myUrl = localStorage.getItem("my_profile");
      ButtonLink = (<NoStyleLink to={"/profile/"+myUrl}>
          <GradientButton
            variant="contained"
            color="primary"
            >
              Return to your profile
          </GradientButton>
        </NoStyleLink>
        )
    }else{
      ButtonLink = (
        <NoStyleLink to="/signup">
          <GradientButton
            variant="contained"
            color="primary"
            >
              Sign up for free
          </GradientButton>
        </NoStyleLink>
      )
    }
    return ButtonLink;
  }

  const RenderError = (title, subtitle, ImgComp, description) => {

    let TheError = (
        <ErrorWrapper>
          <Spacer height="40"/>
          <Typography variant="h1">
            {title}
          </Typography>
          <Spacer height="10"/>
          <Typography variant="subtitle1">
          {subtitle}
          </Typography>
          {/* <Spacer height="20"/>
          {ImgComp} */}
          <Spacer height="50"/>
          <ErrorTextPanel>
            <Typography variant="body1">
            {description}
            </Typography>
          </ErrorTextPanel>
          <Spacer height="40"/>
          {/* {RenderButtonLink()} */}
        </ErrorWrapper>
      )
    return TheError;
  }

  const DetermineError = () =>{
    let errorCode = 404;
    if (props.errorCode !== undefined){
      errorCode = props.errorCode;
    }

    let defType = "Page"
    if (props.failSource !== undefined){
      defType = props.failSource;
    }

    //We default to a 404 so we don't freak out the user
    //We also default to a page error since this is the most common use case
    let title="404";
    let subtitle=defType + " not found";
    let ImgComp = (<MissingImgWrapper/>);
    let description = "We couldn't find the " + defType +" you were looking for"

    if (errorCode === 403){
      title="403";
      subtitle = "Error: Not authorized"
      ImgComp = (<ErrorImgWrapper/>);
      description = "You are not authorized to view this " + defType;
    }
    else if (errorCode === 410){
      title="410";
      subtitle = "Error: "+defType+" is gone"
      ImgComp = (<MissingImgWrapper/>);
      description = "This " + defType + " has been permanently removed";
    }
    else if (errorCode === 500){
      title="500";
      subtitle = "Internal Error"
      ImgComp = (<NoAuthImgWrapper/>);
      description = "This "+defType+" has failed in an unexpected way. Our software development team has been notified and we will fix this issue as soon as possible";
    }
    else if (errorCode === 503){
      title="503";
      subtitle = "Down for Maintenence"
      ImgComp = (<NoAuthImgWrapper/>);
      description = "We are temporarily down for maintenance. Please come back in a few minutes";
    }
    else if (errorCode === 429){
      title="429";
      subtitle = "Too Many Requests"
      ImgComp = (<NoAuthImgWrapper/>);
      description = "Our systems are overloaded right now, please try again in a few minutes";
    }

    return RenderError(title, subtitle, ImgComp, description);
  }


  return (
    <React.Fragment>
      {DetermineError()}
    </React.Fragment>
  )
}
export default DisplayError;