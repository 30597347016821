// react import
import React, { useContext, useState } from 'react';

// import { createPortal } from 'react-dom';

import Snackbar from "../components/Site-Structure/Snackbar"

//Create a new context
const NotificationContext = React.createContext();

//Custom hook that uses this context
export const useNotificationHelper = () => useContext(NotificationContext);

export const NotificationHelper = ({ children }) => {
	//keeps track on whether to show the component or not
	const [isHidden, setIsHidden] = useState(true);
	const [notificationText, setNotificationText] = useState('');
	const [snackBarVariant, setSnackBarVariant] = useState('');
	const [snackBarOpen, setSnackBarOpen] = useState(true)

	const showNotification = async (message, variant) => {
		setIsHidden(false);
		setNotificationText(message);
		setSnackBarVariant(variant);
		setSnackBarOpen(true)
		setTimeout(function () {
			setSnackBarOpen(false);
		  }, 3000);
	};



	const renderNotifier = () => {
		let context;

		if (!isHidden) {
			context = notifier();
		}

		return context;
	};

	const notifier = () => {
		const handleClose = () =>{
			setSnackBarOpen(false)

		}
		return (
			<Snackbar
			displayText = {notificationText}
			variants = {snackBarVariant}
			open={snackBarOpen}
			close = {handleClose}
			/>
		);
	};

	return (
		<NotificationContext.Provider value={{ showNotification }}>
			{renderNotifier()}
			{children}
		</NotificationContext.Provider>
	);
};
