////ProfilePicture.js
///////////////////////////////
//React & Material
import React from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  Avatar,
  Button,
  Hidden
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

//Utils
import { useUploadPhotoHelper } from "../../../Utils/UploadPhotoHelper";

///////////////////
// Main Component
//props include firstName, lastName, profileURL, and buttonText for upload button
function ProfilePicture(props) {
  //Styles the image so the width and height are constant
  const ProfileImage = styled(Avatar)`
    width: 160px !important;
    height: 160px !important;
    @media screen and (max-width: 600px){
      border-radius: 0!important;
    }
  `;
  //Wraps around the grid item of image and its button
  //Allows styling and hover effect
  const ProfileImageWrapper = styled(Grid)`
    position: relative;
    width: 160px !important;
    height: 160px!important;

    @media screen and (max-width: 600px){
      img {
        border-radius: 0!important;
      }
    }

    img, .MuiAvatar-circle{
      width:160px!important;
      height:160px!important;
      border-radius: 160px;
      border:solid 2px #03A9F4;
      background-color:#272727;
      color:white;
    }
    :hover{
      .updateButton {
        display: block !important;
        width: 160px !important;
        height: 80px !important;
        background: rgb(0, 0, 0, 0.7) !important;
        border-bottom-left-radius:  160px;
        border-bottom-right-radius: 160px;
      }
    }
  `;
  //Styles the button modal and its background, display is none until hovered
  const UpdateButton = styled(Button)`
    position: absolute !important;
    top: 75% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: #ffffff !important;
    display: none !important;
  `;

  const MobileUpdateBtn = styled(Button) `
    position: absolute !important;
    bottom:10px;
    right:10px;
    background-color: #3e3c3c !important;
    color: #ffffff !important;
    padding:4px 6px!important;
  `
  //renders the update button modal when editable prop is passed in
  const renderUpdateButton = () =>{
    if(props.editable){
      return(
        <React.Fragment>
          <Hidden xsDown>
            <UpdateButton
            className="updateButton"
            variant="outlined"
            onClick={() => {
              showWidget("profile");
            }}
          >
            <CameraAltIcon fontSize="small" /> <br></br>
            <Typography variant="caption">Edit Profile</Typography>
          </UpdateButton>
        </Hidden>
        <Hidden smUp>
          <MobileUpdateBtn
          className="updateButtonMobile"
          variant="outlined"
          onClick={() => {
            showWidget("profile");
          }}
        >
          <CameraAltIcon fontSize="small" /> <br></br>
          </MobileUpdateBtn>
        </Hidden>
      </React.Fragment>
      )
    }
  }
  //import from upload photo helper
  const { showWidget } = useUploadPhotoHelper();
  return (
    <React.Fragment>
          <ProfileImageWrapper>
            <ProfileImage src={props.profileURL} />
          {renderUpdateButton()}
          </ProfileImageWrapper>

    </React.Fragment>
  );
}
export default ProfilePicture;
