//ImageWithText.js
///////////////////////////////
//React & Material
import React from "react";
import {
  Typography,
  Button,
  Grid,
  Hidden
} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

//Plugins
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Link as ScrollLink } from "react-scroll";

//Utils

//////////////////////////////
//Styled components

//A button with gradient background
const GradientButton = styled(Button)`
  background: linear-gradient(45deg, #2196F3 30%, #03A9F4 90%);
  color: #FFF!important;
`

const DesktopButtonSpacer= styled.div `
  width:40px;
`

const ImageWithTextWrapper = styled.div `
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  .contentGrid{
    @media screen and (max-width: 960px){
      flex-direction:column-reverse!important;
      align-items:center!important;
      img {
        margin-bottom: 40px;
      }
    }
  }
  min-height:${props => props.forceHeight};
  ${props =>
  props.imgHero &&
  css`
    background-image: url(${props => props.imgLink});
    display:flex;
    justify-content:space-around;
    align-items:center;
    width: 100vw;
    .contentGrid{
      justify-content:flex-start!important;
      margin-left:20px;
    }
    @media screen and (max-width: 600px){
      .contentGrid{
        justify-content:center!important;
        margin-left:0!important;
        text-align:center!important;
      }
    }
  `}
  }
`

const TextGridItem = styled(Grid) `
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
`

const ImageGrid = styled(Grid) `
  margin: 0px 20px!important;
`

const TextContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 600px){
    align-items: center;
    justify-content: center;
  }
`

const ContentWrapper = styled.div `
  .contentGrid{
    display: flex;
    justify-content: center;

    ${props =>
      props.imgLeft &&
      css`
        flex-direction: row-reverse;
      `}
    }
`

//The wrapper of two buttons, flex-direction will be column
//at the breakpoint
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  margin-top:20px;
  @media screen and (max-width: 600px){
      flex-direction:column;
      justify-content:space-around;
      margin-bottom:40px;
      margin-top:20px;
      a {
      margin-top:20px;
    }
  }
`

const NoStyleLink = styled(Link) `
  && {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
`

const Spacer = styled.div `
  height: ${props => props.height}px;
`

//The style component of the image passed in
const Img =styled.img`
 max-width: 100%;
`

const DownBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

//////////////////////////////
//Component class
/**
 * Displays a ImageWithText
 */
function ImageWithText(props) {
  ImageWithText.propTypes = {
    /** The title to be displayed */
    title: PropTypes.string,
    /** The description to be displayed */
    description: PropTypes.string,
    /** The gradient button text */
    gButtonText: PropTypes.string,
    /** The gradient button link */
    gButtonLink: PropTypes.string,
    /** The gradient button text */
    eButtonText: PropTypes.string,
    /** The gradient button link */
    eButtonLink: PropTypes.string,
    /**The plain button text */
    pButtonText: PropTypes.string,
    /** The link for the plain button */
    pButtonLink: PropTypes.string,
    /** The link of the image resource */
    imgLink: PropTypes.string,
    /** Makes the bg black and constrains the width */
    debugContrastBg: PropTypes.bool,
    /** Makes the bg black and constrains the width */
    imgLeft: PropTypes.bool
  };

  //also an imgHero prop.
  //Need to update these proptypes soon to use functional components!

  //Default props
  // ImageWithText.defaultProps = {
  //   title: "",
  //   description: "",
  //   gButtonText: "",
  //   gButtonLink: "",
  //   pButtonText: "",
  //   pButtonLink: "",
  //   imgLink: "",
  //   debugContrastBg: false,
  //   imgLeft: false
  // };

  /* This function returns a button with gradient background and
  a plain button if plain button link is passed in
   */
  const renderButtons = () => {

    let GradientButtonComp;
    let PlainButton;
    let ButtonSpacer;

    if (props.gButtonText !== undefined && props.gButtonLink !== undefined){
      GradientButtonComp = (
      <NoStyleLink to={props.gButtonLink} data-test="about-link">
        <GradientButton
          variant="contained"
          data-test="gradient-button"
          >
          {props.gButtonText}
        </GradientButton>
      </NoStyleLink>
      );
      //Add a button spacer if using gradient button
      ButtonSpacer = (
        <Hidden xsDown>
          <DesktopButtonSpacer/>
        </Hidden>
      );
    }

    if (props.eButtonText !== undefined && props.eButtonLink !== undefined){
      GradientButtonComp = (
      <a href={props.eButtonLink} data-test="about-link">
        <GradientButton
          variant="contained"
          data-test="gradient-button"
          >
          {props.eButtonText}
        </GradientButton>
      </a>
      );
      //Add a button spacer if using gradient button
      ButtonSpacer = (
        <Hidden xsDown>
          <DesktopButtonSpacer/>
        </Hidden>
      );
    }

    // //used to manage the redirect back to our page
    // let baseURL = window.location.protocol + "//" + window.location.hostname;
    // //ensures local host works
    // if (window.location.port !== "") {
    //   baseURL = baseURL + ":" + window.location.port;
    // }

    if (props.pButtonText !== undefined && props.pButtonLink !== undefined){
      PlainButton = (
        <NoStyleLink to={props.pButtonLink} data-test="about-link">
      <Button
        variant="outlined"
        data-test="plain-button"
        >
        {props.pButtonText}
      </Button>
      </NoStyleLink>
      );
    }

    return (
      <React.Fragment>
      <ButtonWrapper>
      {GradientButtonComp}
      {ButtonSpacer}
      {PlainButton}
      </ButtonWrapper>
      </React.Fragment>
    );
  }

  /* The function returns a img if a img link
  passed in as a prop, or else render build icon from material ui
   */
  const renderImg = () => {

    let Image;

    if (props.imgHero){
      return (Image)
    }

    if (props.imgLink) {
      Image = (
        <Img src={props.imgLink} data-test ="img"/>
        )
    }else{
      return (<React.Fragment></React.Fragment>)
    }

    const CenterImg = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    `

    return (
      <ImageGrid item xl={3} lg={4} md={4} sm={7} xs={10}>
        <CenterImg>
          {Image}
        </CenterImg>
      </ImageGrid>
    )
  }

  const RenderDescription = () => {
    let Description;
    if (props.description !== undefined){
      Description = (
        <React.Fragment>
          {props.description.split("/n").map((value, index) => {
          return (
            <React.Fragment key={index}>
            <Typography
              variant="subtitle1"
              data-test="description"
            >
              {value}
            </Typography>
            <br/>
            </React.Fragment>
          );
        })}
      </React.Fragment>)
    }else{
      Description =( <Typography variant="subtitle1" data-test="description">{props.description}<br/></Typography>)
    }
    return Description;
  }

  let lgTextCols = 5;
  let mdTextCols = 6;

  //If no image, the text should be wider
  if (props.imgLink === undefined || props.imgHero !== undefined){
    lgTextCols=8;
    mdTextCols=10;
  }

  //allow the size of the title to be defined
  let titleSize = "h4";
  if (props.hsize !== undefined){
    titleSize = props.hsize;
  }


  //Add a fab downbutton if we are the hero element
  let downButton;

  if (props.imgHero){
      downButton = (
      <DownBtnWrapper>
        <ScrollLink
        to="scrolldownanchor"
        smooth={true}
        offset={-50}
        duration={750}>
          <Fab aria-label="scroll down">
            <ArrowDownwardIcon />
          </Fab>
        </ScrollLink>
      </DownBtnWrapper>
    )
  }

  return (
    <ImageWithTextWrapper forceHeight={props.height} imgHero={props.imgHero} imgLink={props.imgLink} data-test="component-wrapper">
        <ContentWrapper imgLeft={props.imgLeft}>
        <Grid container className="contentGrid">
          <TextGridItem item xl={lgTextCols} lg={lgTextCols} md={mdTextCols} sm={10} xs={10}>
            <TextContentWrapper >
              <Typography variant={titleSize} data-test="title">
                {props.title}
              </Typography>
              <Spacer height="20"/>
              {RenderDescription()}
              {renderButtons()}
            </TextContentWrapper>
           </TextGridItem>
           {renderImg()}
           </Grid>
        </ContentWrapper>
        {downButton}
    </ImageWithTextWrapper>
  );
}
export default ImageWithText;