//Template.js
///////////////////////////////
//React & Material
import React from 'react';

//Plugins
import {
    Grid
} from "@material-ui/core";
import styled from "styled-components";

//framer motion
import { motion } from "framer-motion";

//////////////////////////////
//Styled components
//The style component of the image passed in
const Img = styled.img`
    margin-top:20px;
    max-width: 90%;
`
const ImageGrid = styled(Grid)`
 margin: 0px 20px!important;
`
const DecorationLeft = styled.img`
    max-width:80%;
    margin-left:20%;
    transform: translateZ(0);
`
const DecorationRight = styled.img`
    margin-top: 100px;
    margin-left: -20%;
    max-width:80%; 
    transform: translateZ(0);
`
const Animation = styled(motion.div)`
    display:flex;
    z-index: 1;
`
const GridDecoration = styled(Grid)`
    display:flex;
    z-index: 1;
`

//////////////////////////////
//Component Function
/**
 * Displays the left vector component
*/
function LeftVector(props) {
    //renders the vector which is passed in as a prop with animations
    function RenderDecoration() {
        return (
            <GridDecoration item xs={12} sm={8} md={6} lg={5} xl={4}>
                <Animation
                    animate={{ scale: 0.9, opacity: 0.5 }}
                    transition={{
                        yoyo: Infinity,
                        duration: 5,
                        ease: "easeInOut"
                    }}
                >
                    <DecorationLeft src={props.decoLink} />
                </Animation>
                <Animation
                    animate={{ scale: 1.1, opacity: 0.5 }}
                    transition={{
                        yoyo: Infinity,
                        duration: 5,
                        ease: "easeInOut"
                    }}
                >
                    <DecorationRight src={props.decoLink} />
                </Animation>
            </GridDecoration>
        )
    }

    //renders the image which is passed in as a prop
    function RenderImage() {
        let Image;
        if (props.imgLink) {
            Image = (
                <Img src={props.imgLink} data-test="img" />
            )
        } else {
            return (<React.Fragment></React.Fragment>)
        }
        return (
            <ImageGrid item xs={12} sm={8} md={6} lg={5} xl={4}>
                {Image}
            </ImageGrid>
        )

    }

    if (props.withDecoration) {
        return (
            <React.Fragment>
                {RenderDecoration()}
            </React.Fragment>
        )
    } else if (props.withImage) {
        return (
            <React.Fragment>
                {RenderImage()}
            </React.Fragment>
        )
    } else {
        //default case
        return (
            <React.Fragment>
                {RenderImage()}
            </React.Fragment>
        )
    }
}
export default LeftVector;
