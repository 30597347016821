//FooterV2.js
///////////////////////////////
//React & Material
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Hidden
} from '@material-ui/core';
import {
  Link
} from 'react-router-dom';

//Plugins
import styled from 'styled-components';
import { animateScroll as scroll } from "react-scroll";

//Images
import linkedin from './linkedin.svg';
import instagram from './email.svg';
import twitter from './twitter.svg';

//////////////////////////////
//Styled components
const FooterWrapper = styled.div `
&& {
  background-color: #0006;
  width: 100%;
}
`

const FooterText = styled(Typography) `
&& {
  text-align: left;
  padding:15px 0px;
  padding-left:120px;
  color: #FFFFFFaa;
}
`

const FooterMobileText = styled(Typography) `
&& {
  padding:15px 0px;
  text-align: center;
}
`

const NavSocialWrapper = styled.div `
&& {
  padding-left: 120px;
  padding-top:20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #FFFFFF44;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
`

const NavSectionWrapper = styled.div `
&& {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height:150px;
}
`

const SocialSectionWrapper = styled.div `
&& {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 120px;
  height:150px;
}
`

const MainSectionHeader = styled(Typography) `
&& {
  color: #FFFFFFaa;
}
`
const FooterSpacer = styled.div `
  flex-grow: 1;
`

// const LogoImage = styled.img `
//   max-height: 200px;
//   margin-right: 60px;

// `

//////////////////////////////
//Component class
/**
 * Displays a template component
 */
class FooterV2 extends React.Component {

  static propTypes = {
    /** Change what text is displayed in the footer */
    footertext: PropTypes.string,
  }

  /**
   * Given a name for the data test, text to display
   * and a link to send users to, returns a clickable typography
   *
   * @public
   */
  NavLink = (num, text, url) => {
    let NavLink;

    const textDataTest = "nav-text-" + String(num);
    const linkDataTest = "nav-link-" + String(num);

    const NavRow = styled.li `
&& {
      padding: 5px 0px;
}
    `

    const FadeText = styled(Typography) `
&& {
    color: #FFFFFFDD;
}
    `

    const NoStyleLink = styled(Link) `
&& {
      text-decoration: none;
}
    `

    NavLink = (
      <NavRow >
        <div onClick={() => {scroll.scrollToTop()}}>
        <NoStyleLink to={url} data-test={linkDataTest}>
        <FadeText data-test={textDataTest} variant="h6">{text}</FadeText>
        </NoStyleLink>
        </div>
        </NavRow>
    )

    return (
      NavLink
    )
  }

  /**
   * Displays the navigation section
   *
   * @public
   */
  NavSection = () => {
    let NavSection;

    const NavList = styled.ul `
&& {
      list-style: none;
      padding:0;
}
    `

    NavSection = (
      <NavSectionWrapper data-test="nav-section">
          <MainSectionHeader variant="h6" data-test="nav-header">
            Navigation
          </MainSectionHeader>

          <NavList data-test="nav-list">
            {this.NavLink("home", "Home", "/")}
            {this.NavLink("profile", "Portfolio", "/profile")}
            {/* {this.NavLink("services", "Services", "/about")} */}
          </NavList>


        </NavSectionWrapper>
    )

    return (
      NavSection
    )
  }


  /**
   * Given a name for the data test, an icon file link
   * and a link to send users to, returns a clickable icon
   *
   * @public
   */
  SocialLink = (num, image, url) => {
    let NavLink;

    const textTitle = "social-icon-" + String(num);
    const linkTitle = "social-link-" + String(num);

    const NavRow = styled.li `
&& {
      padding: 5px 0px;
      padding-right: 20px;
}
    `

    const Icon = styled.img `
&& {
      width:32px;
}
    `

    NavLink = (
      <NavRow >
          <a  href={url} data-test={linkTitle} target="_blank" rel="noopener noreferrer" >
          <Icon data-test={textTitle} src={image}/>
          </a>
        </NavRow>
    )

    return (
      NavLink
    )
  }


  /**
   * Displays the social section with a list of link images
   *
   * @public
   */
  SocialSection = () => {
    let SocialSection;

    const SocialList = styled.ul `
&& {
      list-style: none;
      padding:0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
}
    `

    SocialSection = (
      <SocialSectionWrapper data-test="social-section">
          <MainSectionHeader variant="h6" data-test="social-header">
            Social Media
          </MainSectionHeader>

          <SocialList data-test="social-list">
            {this.SocialLink("linkedin", linkedin, "https://www.linkedin.com/in/mattbalshaw/")}
            {this.SocialLink("twitter", twitter, "https://twitter.com/BalshawMatt")}
            {this.SocialLink("email", instagram, "mailto:matt@mbm.space")}
          </SocialList>
        </SocialSectionWrapper>
    )
    return (
      SocialSection
    )
  }

  FooterTextSection = () => {
    let FooterTextSection;

    if (this.props.footertext !== undefined) {
      FooterTextSection = (
        <React.Fragment>
        <Hidden xsDown>
        <FooterText data-test="footer-text">
        {this.props.footertext}
      </FooterText>
      </Hidden>
      <Hidden smUp>
      <FooterMobileText data-test="footer-text-mobile">
        {this.props.footertext}
      </FooterMobileText>
      </Hidden>
      </React.Fragment>
      )
    }

    return (
      FooterTextSection
    )
  }

  render() {
    return (
      <FooterWrapper data-test="footer-wrapper">
        <Hidden xsDown>
          <NavSocialWrapper>
            {this.NavSection()}
            {this.SocialSection()}
            <Hidden smDown>
              <FooterSpacer/>
              {/*<LogoImage src={process.env.PUBLIC_URL + '/Subconnect-Gear-Logo-Slogan.png'}/>*/}
            </Hidden>
          </NavSocialWrapper>
        </Hidden>
        {this.FooterTextSection()}
      </FooterWrapper>
    )
  }
}
export default FooterV2;
