//ContactPage.js
///////////////////////////////
//React & Material
import React from 'react';

//Plugins
import styled from 'styled-components';
import { animateScroll as scroll } from "react-scroll";

//Component Imports
import Contact from '../../components/Info-Sections/Contact'

//////////////////////////////
//Styled components

const Spacer = styled.div `
  width: 90vw;
  height: ${props => props.height}px;
`

//////////////////////////////
//Component class
class ContactPage extends React.Component {

  render() {

    scroll.scrollToTop()

    return (
      <React.Fragment>
              <Spacer height="60"/>

      <Contact/>
              <Spacer height="60"/>

      </React.Fragment>
    )
  }
}
export default ContactPage;