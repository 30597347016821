//CoverPhoto.js
///////////////////////////////
//React & Material
import React from "react";
import {
  Button,
  Typography
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

//Plugins
import styled from "styled-components";

//Hooks
import { useUploadPhotoHelper } from "../../../Utils/UploadPhotoHelper";

//////////////////////////////
//Component function
function CoverPhoto(props) {
  //Wraps around the component to make sure all img will have same height and width
  //height and width are determined by the props
  const ComponentWrapper = styled.div`
    height: ${props.height};
    width: 100%;
    border-bottom: solid 1px #03A9F4 !important;
    position: relative;
  `;
  //Wraps around the img to make sure it fills the ComponentWrapper hence has the
  //same height and width
  const ImgWrapper = styled.img`
    height: 100%;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: cover;
    object-position: center;
  `;

  const ButtonWrapper = styled(Button)`
    bottom: 6px;
    right: 15px;
    z-index: 10;
    background-color: #3e3c3c !important;
    color: #ffffff !important;
    padding: 6px 6px !important;
  `;
  //set the state to window width

  const PlaceholderDiv = styled.div`
    height: 100%;
    width: 100%;
    background: #272727 !important;
  `

  const EditCoverText = styled(Typography) `
    margin-left: 10px!important;

    @media screen and (max-width: 600px){
      display:none;
    }
  `

  //The upload photo modal
  const {showWidget} = useUploadPhotoHelper();

  const renderButton = () => {
    // use the buttonStyle to override material-ui button style
    // TODO: change this to styled components, just use !important
    const buttonStyle = {
      position: "absolute",
    };
    //Only render the button when the editable prop is passed in
    if (props.editable) {
      return (
        <ButtonWrapper
          variant="outlined"
          style={buttonStyle}
          onClick={() => {
            showWidget(
              "cover"
            );
          }}
        >
          <CameraAltIcon fontSize="small" />
          <EditCoverText variant="button">
            {props.buttonMessage}
          </EditCoverText>
        </ButtonWrapper>
      );
    }
  };

  const renderImage = () => {

    let Image;

    if (props.imgUrl === ""){
      Image = (
          <PlaceholderDiv/>
        )
    }else{
      Image = (
          <ImgWrapper src={props.imgUrl} />
        )
    }

    return (Image)
  }

  return (
    <ComponentWrapper>
      {renderImage()}
      {renderButton()}
    </ComponentWrapper>
  );
}
export default CoverPhoto;
