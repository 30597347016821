//FeatureList.js
///////////////////////////////
//React & Material
import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Hidden,
  IconButton
} from "@material-ui/core";
import {
  ArrowBackIos,
  ArrowForwardIos,
} from "@material-ui/icons";

//Plugins
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

//Component Imports
import Feature from "../Feature";

//////////////////////////////
//Styled components
const FeatureListWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1200px;
`;

const WholeComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  align-items: center;

  ${props =>
    !props.debugContrastBg &&
    css`
      width: 100vw;
    `};

  ${props =>
    props.debugContrastBg &&
    css`
      background-color: #333333cc;
    `};
`;

const MobileFeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 400px !important;
`;

const FeatureComponentWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainHeading = styled(Typography)`
  text-align: center;
  margin-bottom: 60px !important;
`;


// function MotionPathSVG(props) {

//   const iconVariants = {
//         hidden: {
//           pathLength: 0,
//           opacity: 0,
//         },
//         visible: {
//           pathLength: 1,
//           opacity: 1,
//         }
//       }

//   let theIcon = (
//     <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 24 24">
//     <motion.path
//     d={props.path}
//     variants={iconVariants}
//     initial="hidden"
//     animate="visible"
//     transition={{
//        duration: 1.5, delay:0.3
//     }}
//     />
//     </svg>
//     );

//   return (
//     theIcon
//     )
// }

//////////////////////////////
//Component class
/**
 * Displays a FeatureList component
 */
class FeatureList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: 0,
      direction: 1
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.renderEachFeature = this.renderEachFeature.bind(this);

  }

  static propTypes = {
    /** for styleguidist and debugging, displays a contrasting background */
    debugContrastBg: PropTypes.bool,
    /** The information to use when rendering features */
    featureContent: PropTypes.array.isRequired,
    /** The heading to display, optional */
    title: PropTypes.string
  };

  static defaultProps = {
    debugContrastBg: false
  };

  next = () => {
    let newActive = this.state.activeComponent + 1;

    if (newActive >= this.props.featureContent.length) {
      newActive = 0;
    }

    this.setState({
      activeComponent: newActive,
      direction: 1
    });
  };

  prev = () => {
    let newActive = this.state.activeComponent - 1;
    if (newActive < 0) {
      newActive = this.props.featureContent.length - 1;
    }
    this.setState({
      activeComponent: newActive,
      direction: -1
    });
  };

  renderEachFeature = (title, description, icon, color, fnum, mobile) => {
    let featureDataTest = "featureList-feature-" + String(fnum);
    // let theIcon;

    const variants = {
      largen: {
        transition: { delay: 0.3, duration: 0.4 }
      }
    };

    const FullHeightMotion = styled(motion.div)`
      height: 100%;
    `;

    let mdsize = 4;

    if (this.props.featureContent.length === 4){
      mdsize = 3;
    }


    let FeatureComponent = (
      <FeatureComponentWrapper
        item
        xs={12}
        sm={6}
        md={mdsize}
        data-test={featureDataTest}
      >
        <FullHeightMotion
          whileHover={{ scale: 1.05 }}
          variants={variants}
          animate="largen"
        >
          <Feature
            title={title}
            description={description}
            micon={icon}
            mobile={mobile}
            color={color}
            material={true}
          />
        </FullHeightMotion>
      </FeatureComponentWrapper>
    );

    return FeatureComponent;
  };

  renderFeatures = featureContent => {
    let AllFeatures;
    const featIndex = this.state.activeComponent;
    const direction = this.state.direction;

    const variants = {
      enter: (direction: number) => ({
        x: direction < 0 ? 350 : -350,
        opacity: 0
      }),
      center: {
        zIndex: 1,
        x: 0,
        opacity: 1
      },
      exit: (direction: number) => ({
        zIndex: 0,
        x: direction < 0 ? -350 : 350,
        opacity: 0
      })
    };

    if (featureContent.length < 1) {
      return AllFeatures;
    } else if (featureContent.length > 8) {
      return AllFeatures;
    }

    AllFeatures = (
      <React.Fragment>
        <Hidden xsDown>
          {featureContent.map((value, index) => {
            return (
              <React.Fragment key={index}>
                {this.renderEachFeature(
                  value.title,
                  value.description,
                  value.icon,
                  value.color,
                  index + 1,
                  false
                )}
              </React.Fragment>
            );
          })}
        </Hidden>
        <Hidden smUp>
          <MobileFeatureWrapper data-test="mobile-wrapper">
            <motion.div whileTap={{ scale: 1.2, x: -10 }}>
              <IconButton onClick={this.prev} data-test="prev-button">
                <ArrowBackIos />
              </IconButton>
            </motion.div>
            <AnimatePresence exitBeforeEnter custom={direction} initial={false}>
              <motion.div
                key={featIndex}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                custom={direction}
                transition={{
                  x: { duration: 0.3, type: "tween" },
                  opacity: { duration: 0.25 }
                }}
              >
                {this.renderEachFeature(
                  featureContent[featIndex].title,
                  featureContent[featIndex].description,
                  featureContent[featIndex].icon,
                  featureContent[featIndex].color,
                  featIndex,
                  true
                )}
              </motion.div>
            </AnimatePresence>
            <motion.div whileTap={{ scale: 1.2, x: 10 }}>
              <IconButton onClick={this.next} data-test="next-button">
                <ArrowForwardIos />
              </IconButton>
            </motion.div>
          </MobileFeatureWrapper>
        </Hidden>
      </React.Fragment>
    );

    return AllFeatures;
  };

  render() {
    return (
        <WholeComponentWrapper
          debugContrastBg={this.props.debugContrastBg}
          data-test="featureList-wrapper"
        >
          <MainHeading variant="h3" data-test="featureList-title">
            {this.props.title}
          </MainHeading>

          <FeatureListWrapper
            container
            spacing={8}
            data-test="featureList-components-wrapper"
          >
            {this.renderFeatures(this.props.featureContent)}
          </FeatureListWrapper>
        </WholeComponentWrapper>
    );
  }
}
export default FeatureList;
