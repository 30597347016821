//ProfilePage.js
///////////////////////////////
//React & Material
import React from 'react';

//Component Imports
import ProfileHeader from '../../components/Profile/ProfileHeader';
// import {
//   Typography,
//   Button,
// }
// from '@material-ui/core';
// import styled from 'styled-components';
// import { Link } from "react-router-dom";

//import styled from 'styled-components';

//////////////////////////////
//Styled components
// const Spacer = styled.div `
//   width: 90vw;
//   height: ${props => props.height}px;
// `

// const GradientButton = styled(Button)`
//   background: linear-gradient(45deg, #fc9511 30%, #d22600 90%);
//   color: #FFF!important;
// `
// const CenterTypography = styled(Typography) `
// 	text-align: center;
// `

// const NoStyleLink = styled(Link) `
//   && {
//     text-decoration: none;
//     color: white;
//     cursor: pointer;
//     text-align: center;
//   }
// `
//////////////////////////////
//Component function

function ProfilePage() {

  let ProfileContent;

	ProfileContent = (
  	<React.Fragment>
        <ProfileHeader/>
    </React.Fragment>
	)

  return (
  ProfileContent
  )
}
export default ProfilePage;




