//InfoPage.js
///////////////////////////////
//React & Material
import React from 'react';
import {
  Grid
} from "@material-ui/core";

//Plugins
import styled from 'styled-components';
import { animateScroll as scroll } from "react-scroll";

//Component Imports
import FeatureList from '../../components/Info-Sections/FeatureList'
import ImageWithText from '../../components/Info-Sections/ImageWithText'
import Contact from '../../components/Info-Sections/Contact'

//////////////////////////////
//Styled components

const Spacer = styled.div `
  width: 90vw;
  height: ${props => props.height}px;
`

const WidthGrid = styled(Grid) `
  width: 100vw;
`

//////////////////////////////
//Component class
class InfoPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ref: 'direct'
    }
  }

  render() {
    scroll.scrollToTop()

    const newProcess = process.env.PUBLIC_URL + "/undraw/git-flow-sitting.svg"
    const newNetwork = process.env.PUBLIC_URL + "/undraw/app-responsive.svg"
    const tradeImg = process.env.PUBLIC_URL + "/undraw/flying-man.svg"

    return (
      <React.Fragment>

          <Spacer height="60"/>

          <ImageWithText
            imgLeft
            title="Website Delivery; Create, Maintain or Upgrade"
            description="
            I create beautiful, responsive websites and deliver mobile and web applications on a budget. From full delivery of a web store to SEO and security updates, your web presence needs can be delivered efficiently without overheads or delays. /n
            For new websites, I can manage all aspects of the delivery; domain registration, hosting, design, development and ongoing maintenance. If you have existing hosts or domain registration, I can work within any existing infrastructure you already have./n
            Depending on the scope of your project, I will advise a custom web app, a Wordpress website or a Shopify Store to best serve your requirements and save on cost where possible.
            "
            imgLink={newNetwork}
          />

          <Spacer height="80"/>

          <ImageWithText
            imgRight
            title="Turnkey Software Development"
            description="
            Expand your team's capacity instantly through my network of highly skilled contractors. We slot in to your existing team to deliver your project on a deadline. With experience running Agile teams and large enterprise projects, we ensure a quality, all Australian based team works alongside your team in house or remotely. /n
            If your project needs guidance or leadership, I am able to step in and run the project, creating documentation and best practices that will allow your team to continue to operate independently and more efficiently than before.
            "
            imgLink={newProcess}
          />

          <Spacer height="80"/>

          <ImageWithText
            imgLeft
            title="Resolve Complex Issues"
            description="
            I'm a specialist in your unexpected, complex issues. If your team is stuck on a particular technical issue, don’t waste days hoping it will resolve itself. I offer professional consulting services to resolve individual issues with your project, and provide continued follow-up support to ensure your project delivery is smooth and easily predictable./n
            This support is extended to both individuals and large enterprise teams - if you are building a personal website and have hit a snag, or if your team is stuck implementing a new technology for the first time we can help you. I rely on my deep technical knowledge and network of experts to guide you through your issues to the best resolution.
            "
            imgLink={tradeImg}
          />

        <Spacer height="60"/>

        <FeatureList
            title="Experienced Full-Stack Developer Available For Consultation"
            featureContent={[
            { "title": "OBLIGATION FREE CONSULTATION",
              "icon": "file-phone-outline",
            "color":"#FF9800", },
            { "title": "WORK REMOTELY OR ON-SITE",
              "icon": "briefcase-account-outline",
            "color":"#BCAAA4", },
            { "title": "FLEXIBLE BILLING ARRANGEMENTS",
              "icon": "calendar-heart",
            "color":"#F06292", },
            { "title": "TAILORED TO YOUR BUSINESS",
              "icon": "scissors-cutting",
            "color":"#03A9F4", },
            ]}
          />

          <WidthGrid container>
            <Grid item lg={1} md={1}>
            </Grid>
            <Grid item lg={5} md={5}  xs={12}>
              <ImageWithText
                title="OBLIGATION FREE CONSULTATION"
                description="
                When you give me a call, there is no obligation to pay. We will discuss your specific needs in detail so that we can determine an action plan to solve your issue. We may be able to resolve your issue in a single call - you will not be charged for this. I rely on repeat business and word of mouth referrals from existing clients, your relationship with me is far more important than an invoice. /n
                During our initial call, a quote can be provided along with an estimation on how long the issue will take to solve. My work and billing process is completely transparent at all times, giving you the security you need when working with remote contractors.
                "
                />
            </Grid>
            <Grid item lg={5} md={5}  xs={12}>
            <ImageWithText
                title="TAILORED TO YOUR BUSINESS"
                description="I offer a wide variety of services, from on-demand consulting to turnkey development work. During our initial consultation, we will determine the scope and nature of work to be carried out. I am able to speedily bring together my team of up to 5 contractors to carry our larger projects, or deliver turnkey project assistance./n
                With this arrangement, you do not need to pay large overheads as with many software development companies, as we run our team on a project basis. Using my network of contractors allows you to save on cost and time spent finding an efficient team that can all work together seamlessly. Our team can work remotely if desired, and allows you to upsize and downsize efficiently.
                "
                />

              </Grid>
          </WidthGrid>
          <Spacer height="20"/>

          <WidthGrid container>
            <Grid item lg={1} md={1}>
            </Grid>
            <Grid item lg={5} md={5}  xs={12}>
              <ImageWithText
                title="FLEXIBLE BILLING ARRANGEMENTS"
                description="
                Depending on the nature of the project, billing is resolved through an hourly rate, or packaged together for the project. We will determine this during the initial consultation. On project billing, a project quote is generated within 24 hours, with a 30% deposit upon commencement of work. Due to the wide variety of billing cycles, we tailor your billing arrangements to your business. I accept bank transfer, Paypal and Bitcoin.
                "
                />
                </Grid>
            <Grid item lg={5} md={5}  xs={12}>
                <ImageWithText
                title="WORK REMOTELY OR ON-SITE"
                description="Since the outbreak of Covid-19, many offices are closed or have very limited space for in-house work. I have experience managing development teams remotely, but if your office is available nothing beats face to face communication."
                />
                </Grid>
          </WidthGrid>
        <Spacer height="60"/>
        <Contact/>
        <Spacer height="40"/>

      </React.Fragment>
    )
  }
}
export default InfoPage;