//UploadPhotoHelper.js
///////////////////////////////
//React & Material
import React, { useState, useContext } from 'react';


//Create a new context
const HttpContext = React.createContext();

//Custom hook that uses this context
export const useUploadPhotoHelper = () => useContext(HttpContext);

//Provider of the 'showWidget' method.
export const UploadPhotoHelper = ({ children }) => {

  //Use the Auth0 hook as normal

  //keeps track on whether to show the component or not
  const [isHidden, setIsHidden] = useState(true);

  //keeps the type of the item as a state to pass to flask later
  // i.e. profilePic , coverPic
  // const [imgType, setImgType] = useState("");
  // const [url, setUrl] = useState("");
  // const [profType, setProfType] = useState("");
  // const [adminRequest, setAdmin] = useState(false);

  const adminShowWidget = (imgType, profType, url) =>{
    // setImgType(imgType);
    // setUrl(url);
    // setProfType(profType);
    // setAdmin(true);
    setIsHidden(false);
  }


  //calls the widget to be shown according to the state
  const showWidget = (imgType) => {

    //sets the itemType using the prop passed in
    // setImgType(imgType);
    //update the state to show the component
    //the component renders forcefully by cloudinary which works like magic and this statement has no value
    setIsHidden(false);

  };

  //renders the widget according to the 'isHidden' state
  const renderWidget = () => {
    let context;

    //MAGIC :')
    if (!isHidden) {
      context = (
        uploadWidget()
      )
    }

    return (context);
  }

  //cloudinary widget to handle the uploading of the images with 'custom styling'
  const uploadWidget = () => {

    var widget = window.cloudinary.createUploadWidget(
      {
        cloud_name: "subconnect-com-au",
        upload_preset: "uxadpmpf",
        sources: ["local", "url", "camera"],
        googleApiKey: "<image_search_google_api_key>",
        showAdvancedOptions: false, cropping: true,
        multiple: false,
        defaultSource: "local",
        styles: {
          palette: {
            window: "#404040",
            sourceBg: "#383838",
            windowBorder: "#FF9703",
            tabIcon: "#FF9703",
            inactiveTabIcon: "#FFFFFF",
            menuIcons: "#0094C7",
            link: "#FF9703",
            action: "#00E3C6",
            inProgress: "#00E3C6",
            complete: "#00C981",
            error: "#DF3C3C",
            textDark: "#FFFFFF", textLight: "#FFFFFF"
          }, fonts: { default: null, "'Poppins', sans-serif": { url: "https://fonts.googleapis.com/css?family=Poppins", active: true } }
        }
      },
      function (error, result) {
        postResult(result);
        setIsHidden(true);
      });

    widget.open();
  }

  //post request to axios about the asset information
  const postResult = (resultEvent) => {
    // var values = {}
    console.log("post");
    // if (resultEvent.event === 'success') {
    //   //do admin req
    //   if (adminRequest){
    //     values = {
    //       img_type: imgType,
    //       profile_type: profType,
    //       url: resultEvent.info.secure_url,
    //       profile_url: url,
    //       size: resultEvent.info.bytes, //size is stored as bytes
    //     }

    //     // flaskPost('/admin/action/edit-image', values)
    //     //   .then(resp => {
    //     //     if (resp.status === 200) {
    //     //       //success
    //     //       //snackbar
    //     //       window.location.reload();
    //     //     }
    //     //   })
    //   }else{

    //   //We want to add the url of the change to make
    //   let urlArr = window.location.pathname.split("/");

    //   let profileURL;
    //   //Get the user ID
    //   if (urlArr[2] !== undefined && urlArr[2] !== "") {
    //     profileURL = urlArr[2];
    //   }

    //   let profileType;
    //   //Get the user ID
    //   if (urlArr[1] !== undefined && urlArr[1] !== "") {
    //     profileType = urlArr[1];
    //   }

    //   //json obj for the values which will be posted
    //   values = {
    //     img_type: imgType,
    //     profile_type: profileType,
    //     url: resultEvent.info.secure_url,
    //     profile_url: profileURL,
    //     size: resultEvent.info.bytes, //size is stored as bytes
    //   }

      // flaskPost('/user/action/upload_image', values)
      //   .then(resp => {
      //     if (resp.status === 200) {
      //       //success
      //       //snackbar
      //       window.location.reload();
      //     }
      //   })
    //   }
    // }
  }
  return (
    <HttpContext.Provider
      value={{ showWidget, adminShowWidget }}
    >
      {renderWidget()}
      {children}
    </HttpContext.Provider>
  );
};
