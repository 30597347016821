//ProfileMeta.js
///////////////////////////////
//React & Material
import React from "react";
import { Helmet } from "react-helmet";


//display single badge
function ProfileMeta(props) {
  //styled component for icon
  let description = props.description;
  if (props.description === ""){
    description = "View " + props.title +"'s profile on Subconnect"
  }

  return (
    <React.Fragment>
      <div className="application">
          <Helmet>
              <title>Matt Balshaw | {props.title} </title>
                <meta property="og:description" content={description}/>
                <meta property="og:image" content={props.image}/>
                <meta property="og:title" content={props.title + " | Subconnect"}/>
          </Helmet>
      </div>
    </React.Fragment>
  );
}

export default ProfileMeta;
