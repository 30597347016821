// App.js
///////////////////////////////
// React Import
import React, {useState, useEffect} from 'react';
import { Switch, Route } from 'react-router-dom'

// plugins
import { createGlobalStyle } from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline';

// Hooks
import { UploadPhotoHelper } from "./Utils/UploadPhotoHelper"
import { NotificationHelper } from "./Utils/NotificationHelper"

// Views
import PublicView from './Views/PublicView'
// Pages
import LoadingPage from './Views/Pages/LoadingPage'
import { motion, AnimatePresence } from "framer-motion";



///////////////////////////////
// Styled Components

//Our global style overrides

//The first is for roboto & bg colour
//We also want all skeleton elements to be lighter
//The last is for managing the autofill color
//Previously it was super buggy

const GlobalStyle = createGlobalStyle`
  body, html{
   font-family: 'Roboto', serif;
   background-color: #191919!important;
  }

  .MuiSkeleton-root {
    background-color: #ffffffaa!important;
  }

  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: #ff9800;
    -webkit-text-fill-color: #ff9800;
    box-shadow: 0 0 0 100px #2B2B2B inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

`

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    setTimeout(function(){
      setLoading(false);
    }, 400);

  },[])


  const iconVariants = {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
      }
    }

  const LoadingOrApplication = () => {

    let Application;

    if (loading) {
      Application = (
          <LoadingPage/>
      );
    }else{
      Application = (
        <Switch>
          <Route path='/' component={PublicView}/>
        </Switch>
      );
    }

    return (Application)
  }

  return (
    <UploadPhotoHelper>
    <NotificationHelper>
    <div className="App">
    <CssBaseline/>
    <GlobalStyle />

    <AnimatePresence initial={false}>
    <motion.div
      key={loading}
      variants={iconVariants}
      exit="hidden"
      initial="hidden"
      animate="visible"
      transition={{
         duration: 1.5
      }}
    >
      {LoadingOrApplication()}

    </motion.div>
    </AnimatePresence>
    </div>
    </NotificationHelper>
    </UploadPhotoHelper>
  );
}
export default App;