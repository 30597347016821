//Navbar.js
///////////////////////////////
//React & Material
import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Hidden,
  Typography
} from "@material-ui/core";

//Plugins
import styled from "styled-components";
import { Link } from "react-router-dom";


/////////////////////////
//Styled Components
const AppBarWrapper = styled(AppBar) `
  && {
    background-color: #000000dd;
    border-bottom: 1px solid #eeeeee33;
    width: 100%;
    color:white;
  }
`

//Use the wrapper to wrap the text to make the text a typography
const HeaderTextWrapper = styled(Typography) `
  text-align: center;
  padding: 0px 0px 0px 15px;
`

//Use the span so it is able to style the typography
const HeaderText = styled.span `
  font-weight: 550;
`

const NavbarSpacer = styled.div `
  flex-grow: 1;
`

const Gear = styled.img `
  && {
    width: 50px;
    margin-top:5px;
  }
`

const NoStyleLink = styled(Link) `
  && {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
`

//////////////////////////////
//Component class
function Navbar(props) {
  //Use the link to wrap the about button so it goes to the about page
  const NavBarLink = (text, url) => {
    let NavBarLink;

    NavBarLink = (
      <NoStyleLink to={url} data-test="about-link">
          {text}
      </NoStyleLink>
    );

    return NavBarLink;
  };
  // use the link to wrap the icon so it goes to the home page
  const IconLink = (url) => {
    let IconLink;

    IconLink = (
      <NoStyleLink to={url} data-test="icon-link">
          <Gear src={process.env.PUBLIC_URL + '/main/matt-balshaw.png'} />
        </NoStyleLink>
    );

    return IconLink;
  };
  return (
    <React.Fragment>
      <AppBarWrapper position="fixed" data-test="navbar">
        <Toolbar>
          {IconLink("/")}
          <Hidden only={["xs"]}>
            <HeaderTextWrapper variant="h4">
            <NoStyleLink to={"/"}>
              <HeaderText data-test="header-text">Matt Balshaw</HeaderText>
            </NoStyleLink>
            </HeaderTextWrapper>
          </Hidden>
          <NavbarSpacer />
          <Button>
            {NavBarLink("Home", "/")}
          </Button>
          <Button>
            {NavBarLink("Portfolio", "/profile")}
          </Button>
          {/* <Button>
            {NavBarLink("Services", "/about")}
          </Button> */}
        </Toolbar>
      </AppBarWrapper>
      <Toolbar/>
    </React.Fragment>
  );

}
export default Navbar;