//profiledata.js
//Handles displaying all the information in sidebars

/////////////////////////
// Companies
/////////////////////////

export const rollsRoyce = { 
    "company_name": "Rolls Royce",
    "company_url": "rolls-royce",
    "company_picture": "/profiles/rolls.png"
}

export const cognativ = { 
    "company_name": "App Native",
    "company_url": "app-native",
    "company_picture": "/profiles/cognativ.png"
}

export const subconnectCompany = { 
    "company_name": "Subconnect Group",
    "company_url": "subconnect-group",
    "company_picture": "/favicon.png"
}

export const freelancing = { 
    "company_name": "Web Freelancing",
    "company_url": "freelancing",
    "company_picture": "/profiles/freelancing.png"
}

export const inverse = { 
    "company_name": "Inverse Studios",
    "company_url": "inverse",
    "company_picture": "/profiles/inverse.png"
}

export const simplify = { 
    "company_name": "Simplify Solutions",
    "company_url": "simplify-solutions",
    "company_picture": "/profiles/simplify.png"
}

export const teazel = { 
    "company_name": "Teazel",
    "company_url": "teazel",
    "company_picture": "/profiles/teazel.png"
}

export const monash = { 
    "company_name": "Monash University",
    "company_url": "monash-university",
    "company_picture": "/profiles/monash.png"
}

export const sideProjects = { 
    "company_name": "Side Projects",
    "company_url": "side-projects",
    "company_picture": "/profiles/side.png"
}

/////////////////////////
// Projects
/////////////////////////

export const yocova = { 
    "company_name": "Yocova",
    "company_url": "yocova",
    "company_picture": "/profiles/yocova.png"
}
export const doors = { 
    "company_name": "Rational DOORS",
    "company_url": "doors",
    "company_picture": "/profiles/doors.png"
}

export const goodpods = { 
    "company_name": "Goodpods",
    "company_url": "goodpods",
    "company_picture": "/profiles/goodpods.png"
}

export const politicist = { 
    "company_name": "Politicist",
    "company_url": "politicist",
    "company_picture": "/profiles/politicist.png"
}

export const security = { 
    "company_name": "Security Team",
    "company_url": "security",
    "company_picture": "/profiles/security.png"
}

export const bendigo = { 
    "company_name": "Bendigo Caravans",
    "company_url": "bendigo",
    "company_picture": "/profiles/bendigo.png"
}


export const subconnectProj = { 
    "company_name": "Subconnect",
    "company_url": "subconnect",
    "company_picture": "/favicon.png"
}

export const innablr = { 
    "company_name": "Innablr",
    "company_url": "innablr",
    "company_picture": "/profiles/innablr.png"
}
export const german = { 
    "company_name": "German Language Centre",
    "company_url": "german",
    "company_picture": "/profiles/language-centre.png"
}
export const isf = { 
    "company_name": "ISF",
    "company_url": "isf",
    "company_picture": "/profiles/isf.png"
}
export const AshBuilt = { 
    "company_name": "Ash Built",
    "company_url": "ash-built",
    "company_picture": "/profiles/ashbuilt.png"
}
export const CtSolutions = { 
    "company_name": "CT Solutions",
    "company_url": "ct-solutions",
    "company_picture": "/profiles/cts.png"
}
export const Remfry = { 
    "company_name": "Remfry Electrical",
    "company_url": "remfry",
    "company_picture": "/profiles/remfry.png"
}

export const arrowtale = { 
    "company_name": "Arrowtale App",
    "company_url": "arrowtale-app",
    "company_picture": "/profiles/arrowtale.jpg"
}

export const inverseSite = { 
    "company_name": "Inverse Site",
    "company_url": "inverse-site",
    "company_picture": "/profiles/inverse.png"
}

export const eAlpaca = { 
    "company_name": "eAlpaca",
    "company_url": "ealpaca",
    "company_picture": "/profiles/simplify.png"
}

export const greenTea = { 
    "company_name": "Green Tea",
    "company_url": "green-tea",
    "company_picture": "/profiles/teazel.jpg"
}

export const teazelSite = { 
    "company_name": "Teazel Site",
    "company_url": "teazel-site",
    "company_picture": "/profiles/teazel.png"
}

export const lexi = { 
    "company_name": "Lexi",
    "company_url": "lexi",
    "company_picture": "/profiles/lexi.png"
}

export const extension = { 
    "company_name": "Monash Extension",
    "company_url": "monash-extension",
    "company_picture": "/profiles/monash.png"
}

export const cysca = { 
    "company_name": "CySca",
    "company_url": "cysca",
    "company_picture": "/profiles/cysca.png"
}

export const ncss = { 
    "company_name": "NCSS",
    "company_url": "ncss",
    "company_picture": "/profiles/ncss.png"
}

export const muayThai = {
     "company_name": "Muay Thai",
    "company_url": "muay-thai",
    "company_picture": "/profiles/mukc.png"
}


export const venture = {
    "company_name": "Venture",
    "company_url": "venture",
    "company_picture": "/profiles/side.png"
}

export const medium = {
    "company_name": "Medium Blog",
    "company_url": "medium",
    "company_picture": "/profiles/medium.png"
}

export const hydroponics = {
    "company_name": "Hydroponics",
    "company_url": "hydroponics",
    "company_picture": "/profiles/hydro.jpg"
}

export const blockchain = {
    "company_name": "Blockchain",
    "company_url": "blockchain",
    "company_picture": "/profiles/bitcoin.jpg"
}

export const factAI = {
    "company_name": "FactAI",
    "company_url": "factai",
    "company_picture": "/profiles/side.png"
}

export const movie_matcher = {
    "company_name": "Movie Matcher",
    "company_url": "movie-matcher",
    "company_picture": "/profiles/side.png"
}

export const idea_storm = {
    "company_name": "AI Idea Generator",
    "company_url": "idea-storm",
    "company_picture": "/profiles/side.png"
}

export const prompt_engineer = {
    "company_name": "Prompt Engineer",
    "company_url": "prompt-engineer",
    "company_picture": "/profiles/side.png"
}

/////////////////////////
// Badges
/////////////////////////

export const react = {
    "title": "React",
    "icon": "react",
    "color":"#2196F3",
    "status": "complete",
    "detail_description":"In depth knowledge of React, using yarn, npm, Jest + Enzyme, TDD on front-end, material-ui, styled components and many other frontend libraries.",
}
export const reactNative = {
    "title": "React Native",
    "icon": "react",
    "color":"#2196F3",
    "status": "complete",
    "detail_description":"Experience running several production React Native projects and developing my own side projects",
}
export const python = {
    "title": "Python",
    "icon": "language-python",
    "color":"#EEEEEE",
    "status": "complete",
    "detail_description":"Professional experience working with Python 2.7 and 3.X+, using python for scripting as well as webservers such as django, flask and cherrypy.",
}
export const fastAPI = {
    "title": "FastAPI",
    "icon": "language-python",
    "color":"#EEEEEE",
    "status": "complete",
    "detail_description":"Experience using the FastAPI framework in high performance cloud environments",
}
export const docker = {
    "title": "Docker",
    "icon": "docker",
    "color":"#03A9F4",
    "status": "complete",
    "detail_description":"Extensive experience with docker, with experience in kubernetes, docker stacks, custom images, creating CI/CD deployments, development environments and web app microservices.",
}
export const javascript = {
    "title": "Javascript",
    "icon": "language-javascript",
    "color":"#FFEB3B",
    "status": "complete",
    "detail_description":"In depth experience with a variety of frameworks including React and Angular, serverside with Node, vanilla Javascript experience with Jquery and many libraries such as D3.js, crossfilter and more.",
}
export const cSharp = {
    "title": "C Sharp",
    "icon": "language-csharp",
    "color":"#CE93D8",
    "status": "complete",
    "detail_description":"In depth experience, working on Unity 4 and 5 to create cross-platform applications",
}
export const sql = {
    "title": "SQL",
    "icon": "database-check",
    "color":"#26A69A",
    "status": "complete",
    "detail_description":"Managed complex and large scale relational database systems and created documentation including entity relationship diagrams and API diagrams in use on production web applications.",
}
export const aws = {
    "title": "AWS",
    "icon": "aws",
    "color":"#FFC107",
    "status": "complete",
    "detail_description":"Experience setting up scalable AWS architectures, consulting with clients moving from cPanel to AWS, experience reducing cost on existing AWS architectures and auditing the reliability of architectures.",
}
export const gcp = {
    "title": "GCP",
    "icon": "cloud-braces",
    "color":"#FFC107",
    "status": "complete",
    "detail_description":"Certified Google Cloud Professional, with experience managing complex production Google Cloud Platform instances, adept with compute engine, app engine, cloud sql, cloud storage, cloud scheduler, and many other GCP product offerings.",
}
export const angular = {
    "title": "Angular",
    "icon": "angular",
    "color":"#F44336",
    "status": "complete",
    "detail_description":"In depth knowledge, creating many web applications for jet engine engineers to assist with collaboration and data visualisation.",
}
export const php = {
    "title": "PHP",
    "icon": "language-php",
    "color":"#2196F3",
    "status": "complete",
    "detail_description":"In depth knowledge, managing complex production facing PHP scripts and assisting clients with complex issues in existing production environments.",
}
export const cicd = {
    "title": "CI/CD",
    "icon": "gitlab",
    "color":"#FF5722",
    "status": "complete",
    "detail_description":"In depth knowledge creating CI/CD best practices for a small business development teams, using Bitbucket, Gitlab CI/CD and buildkite. Automatic testing & automatic deployment following the git-flow model, and built in processes for code reviews.",
}
export const node = {
    "title": "Node.js",
    "icon": "nodejs",
    "color":"#4CAF50",
    "status": "complete",
    "detail_description":"In depth knowledge of Node package manager and using node to run Javascript applications serverside.",
}
export const sass = {
    "title": "SASS",
    "icon": "sass",
    "color":"#F06292",
    "status": "complete",
    "detail_description":"In depth experience using SASS on large production facing projects.",
}
export const django = {
    "title": "Django",
    "icon": "alpha-d-box",
    "color":"#8BC34A",
    "status": "complete",
    "detail_description":"Experience using Django on large production proijects.",
}
export const flask = {
    "title": "Flask",
    "icon": "flask-round-bottom",
    "color":"#EEEEEE",
    "status": "complete",
    "detail_description":"In depth experience managing complex Flask blueprints, automated testing, experience using TDD best practices for Flask",
}
export const ruby = {
    "title": "Ruby",
    "icon": "language-ruby-on-rails",
    "color":"#F44336",
    "status": "complete",
    "detail_description":"Working experience with Ruby on rails, delivering full stack web applications and auditing existing ones.",
}
export const firebase = {
    "title": "Firebase",
    "icon": "firebase",
    "color":"#FFC107",
    "status": "complete",
    "detail_description":"In depth experience managing many firebase projects, using firebase authentication and firestore database for both web and mobile frontends",
}
export const java = {
    "title": "Java",
    "icon": "language-java",
    "color":"#FF9800",
    "status": "complete",
    "detail_description":"In depth experience with Java, creating native Android applications and mods for Java based games.",
}
export const Wordpress = {
    "title": "Wordpress",
    "icon": "wordpress",
    "color":"#EEEEEE",
    "status": "complete",
    "detail_description":"In depth experience with a variety of Wordpress themes and plugins. Experience modifying plugins to best suit client needs, and creating multi-website architectures to reduce hosting costs for low traffic clients.",
}
export const jquery = {
    "title": "Jquery",
    "icon": "jquery",
    "color":"#2196F3",
    "status": "complete",
    "detail_description":"In depth experience using Jquery with vanilla Javascript to manipulate the DOM.",
}
export const nginx = {
    "title": "Nginx",
    "icon": "alpha-n-circle-outline",
    "color":"#4CAF50",
    "status": "complete",
    "detail_description":"In depth experience configuring complex server requirements, caching, SSL certificates, authentication, proxy pass-through, port limiting, sub-domains, mobile redirects and user agent identification",
}
export const apache = {
    "title": "Apache",
    "icon": "feather",
    "color":"#F06292",
    "status": "complete",
    "detail_description":"In depth experience configuring complex server requirements, authentication, SSL certificates, caching, error pages and access rules.",
}
export const dxl = {
    "title": "DXL (Based on C++)",
    "icon": "language-c",
    "color":"#CE93D8",
    "status": "complete",
    "detail_description":"Working knowledge of DXL. DXL is a programming language very close to C++, but with some additional functionality relating specifically to the operation of Rational DOORS. I completed maintenance and the creation of several mission critical DXL scripts.",
}

export const shopify = {
    "title": "Shopify",
    "icon": "alpha-s-circle-outline",
    "color":"#FFFFFF",
    "status": "complete",
    "detail_description":"In depth experience creating and maintaining web stores. I have created custom Plugins and used a variety of themes, customizing them in Shopify's liquid language to best suit client needs.",
}

export const liquid = {
    "title": "Liquid",
    "icon": "alpha-l-circle-outline",
    "color":"#FFFFFF",
    "status": "complete",
    "detail_description":"In depth experience using liquid to customise themes and deliver responsive stores on Shopify",
}

export const amp = {
    "title": "AMP",
    "icon": "flash-circle",
    "color":"#2196F3",
    "status": "complete",
    "detail_description":"In depth experience, creating AMP website versions of existing websites & managing Wordpress AMP plugins.",
}

export const secPlus = {
    "title": "Security+",
    "icon": "security",
    "color":"#2196F3",
    "status": "complete",
    "detail_description":"Comptia Security+ certified professional",
}
