//About.js
///////////////////////////////
//React & Material
import React, { useState, useEffect } from 'react';
import {
	Paper,
	Typography,
	TextField,
	Button,
	Divider,
	Toolbar
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

//Plugins
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

//Auth0Helper for flask calls
// import { useNotificationHelper } from '../../../Utils/NotificationHelper';

//////////////////////////////
//Styled components
const AboutForm = styled(Paper)`
	padding: 15px 0;
	width: 100%;
	background-color: #272727 !important;
`;

const TitleWrapper = styled(Toolbar)`
  padding: 0 35px !important;
  min-height:30px !important;
`
const Title = styled(Typography)`
	flex: 1;
	font-weight: bold;
`;

const AboutContentWrap = styled.div`
	padding: 0 35px;
`;

const ButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
`;

const HorDivider = styled(Divider)`
  margin-top: 8px !important;
  margin-bottom: 20px !important;
  background-color: #03A9F4 !important;
`
const EditButton = styled(Button)`
  background-color: #3e3c3c !important;
  margin-bottom:5px;
  color: #ffffff !important;
`

const CancelBtn = styled(Button)`
	margin-left: 20px !important;
`;

const EmptyTextWrap = styled(Typography)`
	line-height: 0 !important;
`;
const ReadmoreButtonWrap = styled(Button)`
	margin-top: 15px !important;
`;
const ContentWrapper = styled.div`
	width: 100%;
	word-wrap: break-word;
`;
//////////////////////////////
//About Component Function
/**
 * Displays a about component for the user.
 * Switches from text area to textfield on click of edit
 * Validates the about text if edited.
 * Sends form data to flask server
 */
function About(props) {
	const [editMode, setEditMode] = useState(false);
	const [expandedText, setExpandedText] = useState(true);

	// const { showNotification } = useNotificationHelper();

	const [userAbout, setUserAbout] = useState('Loading...');

	//This function will run every time the prop is changed
	useEffect(() => {
		//set default user about if empty
		if (props.aboutdata === '') {
			setUserAbout('Edit your profile description');
		} else {
			setUserAbout(props.aboutdata);
		}
	}, [props.aboutdata]);

	const RenderEditableAbout = () => {
		let AboutForm = (
			<Formik
				initialValues={{
					about_data: userAbout,
				}}
				onSubmit={(values, { setSubmitting }) => {
					//We want to add the url of the change to make
					// let urlArr = window.location.pathname.split('/');

					// let profileURL;
					// //Get the user ID
					// if (urlArr[2] !== undefined && urlArr[2] !== '') {
					// 	profileURL = urlArr[2];
					// }

					// let profileType;
					// //Get the user ID
					// if (urlArr[1] !== undefined && urlArr[1] !== '') {
					// 	profileType = urlArr[1];
					// }

					// let sendValues = {
					// 	about_data: values.about_data,
					// 	profile_url: profileURL,
					// 	profile_type: profileType,
					// };

					setSubmitting(true);
					// flaskPost('/user/action/update_about', sendValues)
					// 	.then(resp => {
					// 		if (resp.status === 200) {
					// 			//success
					// 			showNotification('About section updated successfully!', 'success');
					// 			setUserAbout(values.about_data);
					// 			setEditMode(false);
					// 		} else {
					// 			//allow to resubmit the about content
					// 			setSubmitting(false);
					// 		}
					// 	})
					// 	.catch(error => {
					// 		// Display error not sent
					// 		showNotification('An error occurred while updating, please try again!', 'error');
					// 		setTimeout(function() {
					// 			setSubmitting(false);
					// 		}, 3000);
					// 	});
				}}
				validationSchema={Yup.object().shape({
					about_data: Yup.string()
						.min(1, 'Too short')
						.max(10000, 'Too long')
						.required('Required'),
				})}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						isValid,
					} = props;
					return (
						<form onSubmit={handleSubmit}>
							<TextField
								variant="outlined"
								error={errors.about_data && touched.about_data}
								label="About"
								name="about_data"
								multiline
								value={values.about_data}
								onChange={handleChange}
								onBlur={handleBlur}
								helperText={(errors.about_data && touched.about_data && errors.about_data) || ' '}
								fullWidth
								margin="dense"
							/>

							<ButtonWrap>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={isSubmitting || !isValid}
								>
									Submit
								</Button>
								<CancelBtn variant="contained" color="accent" onClick={() => setEditMode(false)}>
									Cancel
								</CancelBtn>
							</ButtonWrap>
						</form>
					);
				}}
			</Formik>
		);
		return AboutForm;
	};

	const trunc = about => {
		var index, len, ch;
		var value = 0;
		var limitChecked = false;
		var limit = 0;

		for (index = 0, len = about.length; index < len; ++index) {
			ch = about.charAt(index);
			//checks for the number of characters and if it
			//determines a new line the value increases by 100
			ch === '\n' ? (value += 100) : (value += 1);
			//keeps track when the string passes the 600 value mark
			if (value > 600 && !limitChecked) {
				limit = index;
				limitChecked = true;
			}
		}
		//returns the sliced text
		return about.slice(0, limit);
	};

	const RenderPartialUserAbout = () => {
		if (expandedText) {
			//display the whole text
			return (
				<React.Fragment>
					<ContentWrapper>
						{userAbout.split('\n').map((value, index) => {
							if (value.length > 0) {
								return (
									<React.Fragment key={index}>
										<Typography variant="body1">{value}</Typography>
									</React.Fragment>
								);
							} else {
								return (
									<React.Fragment key={index}>
										<EmptyTextWrap variant="body1">{value}</EmptyTextWrap>
										<br />
									</React.Fragment>
								);
							}
						})}
					</ContentWrapper>
				</React.Fragment>
			);
		} else {
			//display only three lines of it with the read more button
			return (
				<React.Fragment>
					<ContentWrapper>
						{trunc(userAbout)
							.split('\n')
							.map((value, index) => {
								if (value.length > 0) {
									return (
										<React.Fragment key={index}>
											<Typography variant="body1">{value}</Typography>
										</React.Fragment>
									);
								} else {
									return (
										<React.Fragment key={index}>
											<EmptyTextWrap variant="body1">{value}</EmptyTextWrap>
											<br />
										</React.Fragment>
									);
								}
							})}
						<ReadmoreButtonWrap
							variant="outlined"
							color="primary"
							onClick={() => {
								setExpandedText(true);
							}}
						>
							Read more
						</ReadmoreButtonWrap>
					</ContentWrapper>
				</React.Fragment>
			);
		}
	};

	const RenderFullUserAbout = () => {
		return (
			<React.Fragment>
				<ContentWrapper>
					{userAbout.split('\n').map((value, index) => {
						return (
							<React.Fragment key={index}>
								<Typography variant="body1">{value}</Typography>
								<br />
							</React.Fragment>
						);
					})}
				</ContentWrapper>
			</React.Fragment>
		);
	};

	const isTooLong = () => {
		var index, len, ch;
		var value = 0;

		for (index = 0, len = userAbout.length; index < len; ++index) {
			ch = userAbout.charAt(index);
			//checks for the number of characters and if it determines a new line the value increases by 100
			ch === '\n' ? (value += 100) : (value += 1);
		}
		if (value > 300) {
			return true;
		} else {
			return false;
		}
	};

	const RenderAboutSection = () => {
		let AboutSection;

		if (editMode) {
			AboutSection = RenderEditableAbout();
		} else {
			//checks whether the value of 'about' is long enough to have a read more button
			if (isTooLong()) {
				AboutSection = RenderPartialUserAbout();
			} else {
				//when the about section is less than 3 lines or 300 characters
				AboutSection = RenderFullUserAbout();
			}
		}
		return AboutSection;
	};

	const RenderEditButton = () => {
		let RetEdit;

		if (props.editable) {
			RetEdit = (
				<EditButton variant="contained" startIcon={<EditIcon />} onClick={() => setEditMode(true)}>
					edit
				</EditButton>
			);
		}

		return RetEdit;
	};

	return (
		<AboutForm>
			<TitleWrapper>
				<Title variant="h4" data-test="login-header">
					About
				</Title>
				{RenderEditButton()}
			</TitleWrapper>
			<HorDivider />
			<AboutContentWrap>{RenderAboutSection()}</AboutContentWrap>
		</AboutForm>
	);
}
export default About;
