//DefaultPage.js
///////////////////////////////
//React & Material
import React from 'react';

//Plugins
import styled from 'styled-components';
import { animateScroll as scroll } from "react-scroll";

//Component Imports
import FeatureList from '../../components/Info-Sections/FeatureList'
import ImageWithText from '../../components/Info-Sections/ImageWithText'
import Contact from '../../components/Info-Sections/Contact'


//////////////////////////////
//Styled components

const Spacer = styled.div `
  width: 90vw;
  height: ${props => props.height}px;
`

//////////////////////////////
//Component class
class DefaultPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ref: 'direct'
    }
  }

  render() {

    const heroImg = process.env.PUBLIC_URL + "/profiles/cover-dark.jpg";
    const appsImg = process.env.PUBLIC_URL + "/undraw/standing-graph.svg";
    const monitorsImg = process.env.PUBLIC_URL + "/undraw/monitors-programming.svg";
    const AppResponsive = process.env.PUBLIC_URL + "/undraw/app-responsive.svg";
    const youtubeThumb = process.env.PUBLIC_URL + "/small-youtube-thumb.jpg";

    scroll.scrollToTop()

    return (
      <React.Fragment>
        <ImageWithText
        imgHero
        height="93vh"
        hsize="h3"
        title="TECHNICAL LEADER GUIDING SUCCESSFUL SOFTWARE PROJECTS"
          gButtonText="View Portfolio"
          gButtonLink="/profile"
          // pButtonText="Services"
          pButtonLink="/about"
          imgLink={heroImg}
        />
        <Spacer height="20"/>
        <div id="scrolldownanchor"></div>
        <Spacer height="40"/>

        <ImageWithText
          imgLeft
          title="YEARS OF TECHNICAL EXCELLENCE"
          description="I'm Matt Balshaw, a tech-savvy web developer and entrepreneur with extensive industry experience./n
          With a Bachelor of Computer Science degree from Monash University, I've held roles such as Head of Development at App Native and Subconnect, and Software Engineer Praktikant at Rolls Royce Aerospace in Berlin.
          Proficient in Python, JavaScript, web servers, Postgres, React, and Docker, I'm equipped to tackle frontend, backend, and devops roles./n
          I'm fluent in English and conversational in German, and I'm passionate about personal projects like Movie Matcher and my AI-focused YouTube channel, Prompt Engineer.
          My unique blend of technical expertise, leadership experience, and entrepreneurial spirit make me a valuable addition to any development team."
          imgLink={monitorsImg}
          // gButtonText="Contact Now"
          gButtonLink="/contact"
        />

        <Spacer height="60"/>

        <FeatureList
          title="PRIMARY AREAS OF EXPERTISE"
          featureContent={[
          { "title": "REACT & REACT NATIVE",
            "description": "Several years of experience across React and React native, capable of advanced front-end design",
            "icon": "react",
            "color":"#03A9F4",
          },
          { "title": "PYTHON",
            "description": "Many years of professional experience with python webservers including Django and Flask",
            "icon": "language-python",
            "color":"#FFEB3B",
          },
          { "title": "NODE",
            "description": "Adept back-end skills across Node.js frameworks such as Express.js, Gatsby and Next.js",
            "icon": "nodejs",
            "color":"#9FC11A",
          },
          { "title": "WORDPRESS",
            "description": "Extensive experience creating bespoke websites with CMS's including Wordpress and Shopify",
            "icon": "wordpress",
            "color":"#EEEEEE",
          },
          { "title": "RDBMS & NOSQL",
            "description": "Data Science background with experience using Postgres, MySQL and Firestore in production",
            "icon": "database-check",
            "color":"#90A4AE",
          },
          { "title": "CLOUD MANAGEMENT",
            "description": "Multiple years experience administrating production environments across AWS and GCP",
            "icon": "aws",
            "color":"#FF9800",
          },
         ]} />

         <Spacer height="60"/>

         <ImageWithText
          imgRight
          title="CHECK OUT MY YOUTUBE CHANNEL"
          description="Take a moment to visit my YouTube channel, where I combine music and visuals in an interesting way using stable diffusion, an AI image generator, and animations./n
          These technologies come together to produce engaging music videos that capture your attention. The channel delves into what can be achieved when AI and human creativity collaborate, expanding our understanding of art./n
          If you're intrigued by the blend of technology and artistry, check out my YouTube channel and experience these unique music videos for yourself."
          imgLink={youtubeThumb}
          eButtonText="visit"
          eButtonLink="https://www.youtube.com/channel/UCuiZTyoVJAgH2jwKwy5xo7A"
        />

        <Spacer height="40"/>
        {/* <Contact/> */}
        {/* <Spacer height="40"/> */}
      </React.Fragment>
    )
  }
}
export default DefaultPage;