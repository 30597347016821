import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import styled, { css } from "styled-components";


const SuccessSnack = styled(Snackbar)`
  div {
    ${props =>
      props.variants === "success" &&
    css`
      background-color: #00C851;
      color: white;
      `}
      ${props =>
    props.variants === "error" &&
    css`
      background-color: #ff6666;
      color: white;
      `}
  }
`

function CustomSnackbar(props) {

  CustomSnackbar.propTypes = {
    success: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    open: PropTypes.bool,
    variants:PropTypes.string,
    displayText: PropTypes.string.isRequired,
  };
    return (
      <div>
        {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
        <SuccessSnack
          variants= {props.variants}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={props.open}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{props.displayText}</span>}
        />
      </div>
    )
}
export default CustomSnackbar;

