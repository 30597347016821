//Feature.js
///////////////////////////////
//React & Material
import React from 'react';
import {
  Typography
} from '@material-ui/core';

//Plugins
import styled, { css } from 'styled-components';

//////////////////////////////
//Styled components
const FeatureWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding:0 20px;
  max-width: 300px;
  overflow:hidden;
    ${props =>
    props.debugContrastBg && css `
      background-color:black;
    `};
`

const IconWrapper = styled.div `
  text-align: center;
  margin-bottom:-20px;

`

const FeatureText = styled(Typography) `
  text-align: center;
  margin: 5px 0!important;
  margin-bottom: 20px!important;
`

//////////////////////////////
//Component class
/**
 * Displays a Feature component
 */
function Feature(props) {

  const renderCorrectOrder = () => {

    let IconAndTitle;

    const Icon = styled.span`
      color: ${props.color} !important;
      font-size: 11em;
      margin-left: 8px!important;
      width:22px!important;
    `;

    if (props.material) {
      IconAndTitle = (
        <div data-test="mobile-order">
      <IconWrapper>
      <Icon
        className={"mdi mdi-" + props.micon}
      >
        </Icon>
        </IconWrapper>
      <FeatureText variant="h6" data-test="feature-title">
        {props.title}
      </FeatureText>
    </div>
      )
    } else {
      IconAndTitle = (
      <div data-test="desktop-order">
        <Icon
          className={"mdi mdi-" + props.micon}
        >
        </Icon>
        <FeatureText variant="h6" data-test="feature-title">
          {props.title}
        </FeatureText>
      </div>
    )
    }

    return (
      IconAndTitle
    )
  }

  return (
    <FeatureWrapper debugContrastBg={props.debugContrastBg} data-test="feature-wrapper">
    {renderCorrectOrder()}
      <FeatureText variant="body1" data-test="feature-description">
        {props.description}
      </FeatureText>
    </FeatureWrapper>
  )
}
export default Feature;