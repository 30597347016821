//LoadingPage.js
///////////////////////////////
//React & Material
import React from 'react';

//Plugins
import styled from 'styled-components';

//Component Imports
import Loading from '../../components/Site-Structure/Loading'

const Container = styled.div `
  // border: red 10px;
`
//////////////////////////////
//Component class
class LoadingPage extends React.Component {

  render() {

    return (
      <Container>
        <Loading displayText="Loading..."/>
      </Container>
    )
    }
}
export default LoadingPage;