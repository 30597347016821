//Sidebar.js
///////////////////////////////
//React & Material
import React from 'react';
import {
  Paper,
  List,
  ListItem,
  Divider,
  Link,
  Tooltip,
  Avatar,
  Typography,
} from '@material-ui/core';
// import Location from '../Location/index.js';

//Plugins
import styled from 'styled-components';

// import ViewAsButton from '../ViewAsButton';
// import ManageUsers from '../ManageUsers'
// import InviteUser from '../InviteUser'

import { withRouter } from "react-router";
// import ApplyBadge from '../ApplyBadge/ApplyBadge.js';

//////////////////////////////
//Styled components
const SidebarForm = styled(Paper)`
  width: 100%;
  background-color: #272727 !important;
`;

// const ItemWrapper = styled(ListItem)`
//   display: flex;
//   justify-content: center !important;
// `;

const ItemWrapperLeft = styled(ListItem)`
  display: flex;
  justify-content: center; !important;
`;

const ExistingProfile = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  && {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
`
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center !important;
  margin:12px;
`

const HeadingDivider = styled(Divider)`
  background-color:#03A9F4 !important;
`
//This component will eventually have an edit button
//This will allow for editing of your role details
//Then, we'll move it to another file
//For now we'll leave it here.

const ForceRouteSimpleCompanyDisplay = withRouter(SimpleCompanyDisplay);

function SimpleCompanyDisplay(props) {


  const UserPicNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:40%;

    @media screen and (max-width: 600px){
      width:unset;
      margin-bottom:10px;
    }

  `
  const ProfileName = styled(Typography)`
    margin-left: 10px!important;
  `
  const CompanyListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width:100%;
    > div {
      margin-top:5px;
      margin-bottom: 5px;
      width:100%;
    }
    > div > a{
      max-width: 100%;
    }
    .MuiDivider-root:last-child{
      display:none;
    }
  `

  const whyAreLinksBrokenWeHaveToManually = (to) => {

    //manual override for return to profile
    if (to === "/experience/profile"){
      props.history.push("/profile");
    }else{
      props.history.push(to);
    }
  }

  const RenderEachCompany = (cName, cUrl, cPic) => {
    return (
      <React.Fragment>
        <UserPicNameWrapper>
          <Tooltip
            title={"View " + cName}
            placement="left"
          >
            <ExistingProfile
              onClick={() => whyAreLinksBrokenWeHaveToManually("/experience/" + cUrl)}
            >
              <Avatar
                alt="avatar"
                src={cPic}
                size="200" />
              <ProfileName
                noWrap
                variant="body2"
              >
                {cName}
              </ProfileName>
            </ExistingProfile>
          </Tooltip>
        </UserPicNameWrapper>
      </React.Fragment>
    )
  }


  // if (props.companies === undefined && props.projects !== undefined){
  //   return(null)
  // }

    let Companies;
    if (props.companies !== undefined) {
    Companies = (
      <React.Fragment>
      <ItemWrapperLeft>
      <CompanyListWrapper>

        {props.companies.map((value, index) => {
          return (
            <React.Fragment key={index}>
              {RenderEachCompany(value.company_name, value.company_url, value.company_picture)}
            </React.Fragment>
          );
        })}
      </CompanyListWrapper>
      </ItemWrapperLeft>
    </React.Fragment>
    )
  }

  let Projects;

  if (props.projects !== undefined) {
    Projects = (
      <React.Fragment>
      <ItemWrapperLeft>
      <CompanyListWrapper>
        <Typography variant="body1">
          Projects:
      </Typography>
        {props.projects.map((value, index) => {
          return (
            <React.Fragment key={index}>
              {RenderEachCompany(value.company_name, value.company_url, value.company_picture)}
            </React.Fragment>
          );
        })}
      </CompanyListWrapper>
      </ItemWrapperLeft>
    </React.Fragment>
    )
  }

  let ProfileLink;

    //Get the array that contains the user ID
    let urlArr = window.location.pathname.split("/");

  if (urlArr[2] !== undefined ){
  ProfileLink = (
      <React.Fragment>
      <Divider />
      <ItemWrapperLeft>
      <CompanyListWrapper>
        <Typography variant="body1">
          Return To Profile:
      </Typography>
        {RenderEachCompany("Matt Balshaw", "profile", "/profiles/matt.jpeg")}
      </CompanyListWrapper>
      </ItemWrapperLeft>
    </React.Fragment>
    )


  }


  let ShowDiv;

  if (props.companies !== undefined && props.projects !== undefined){
    ShowDiv = (<Divider />);
  }

  return (
    <React.Fragment>
        {Companies}
        {ShowDiv}
        {Projects}
        {ProfileLink}
    </React.Fragment>
  )
}


function Sidebar(props) {

  //Need to not show manageusers if no roles (user profile)

  //sidebar takes in all user data and uses it to determine what to show


  const RenderUserSidebar = () => {

    //Render all if the user is you
    //else, just show address and companies

    // let isMe = props.pageData.is_me;

    // let Address;
    // let ChangeView;
    // let InviteButton;
    // let ManageVerifications;
    // Address = (
    //   <ItemWrapper>
    //     <Location
    //       type="user"
    //       location={props.pageData.address}
    //       editable={props.pageData.is_me}
    //     />
    //   </ItemWrapper>
    // )

    // if (isMe) {
    //   ChangeView = (
    //     <React.Fragment>
    //       <Divider />
    //       <ItemWrapper className="item">
    //         <ViewAsButton
    //           changeViewChoice={props.changeViewChoice}
    //           viewChoice={props.viewChoice}
    //         />
    //       </ItemWrapper>
    //     </React.Fragment>
    //   )

    //   InviteButton = (
    //     <React.Fragment>
    //       <Divider />
    //       <ItemWrapper className="item">
    //         <InviteUser />
    //       </ItemWrapper>
    //     </React.Fragment>
    //   )

    //   ManageVerifications = (
    //     <React.Fragment>
    //       <Divider />
    //       <ItemWrapper>
    //       <ApplyBadge/>
    //       </ItemWrapper>
    //     </React.Fragment>
    //   )
    // }

    return (
      <React.Fragment>
        <HeadingWrapper>
          <Typography variant="h5">
            Experience
          </Typography>
        </HeadingWrapper>
        <HeadingDivider />

        <ForceRouteSimpleCompanyDisplay
            companies={props.pageData.companies}
            project={props.pageData.projects}
          />

        </React.Fragment>
    );
  }

  const RenderCompanySidebar = () => {

    // Use the pagedata to determine if the company
    // is editable when user role 1 2 or 3
    // let editable = false;
    // let myRole = props.pageData.my_role
    // if (myRole === 1 || myRole === 2 || myRole === 3) {
    //   editable = true;
    // }

    //Display view users if just a staff member
    //They wont be able to do anything however
    // let companyMember = false;
    // if (editable || myRole === 4) {
    //   companyMember = true;
    // }

    // let CLocation;
    // let CManage;

    // CLocation = (
    //   <React.Fragment>

    //     <ItemWrapper>
    //       <Location
    //         type="company"
    //         location={props.pageData.address}
    //         editable={editable}
    //       />
    //     </ItemWrapper>
    //   </React.Fragment>
    // )

    // if (companyMember) {
    //   CManage = (
    //     <React.Fragment>
    //       <Divider />
    //       <ItemWrapper className="item">
    //         <ManageUsers
    //           userRoles={props.pageData.roles}
    //           myRole={props.pageData.my_role}
    //         />
    //       </ItemWrapper>
    //     </React.Fragment>
    //   )
    // }

    return (
      <React.Fragment>
        <HeadingWrapper>
          <Typography variant="h5">
            Related
          </Typography>
        </HeadingWrapper>
        <HeadingDivider />
        <ForceRouteSimpleCompanyDisplay
            companies={props.pageData.companies}
            projects={props.pageData.projects}
          />
{/*        {CLocation}
        {CManage}*/}
      </React.Fragment>
    );
  }


  const ChooseCompanyOrUser = () => {

    if (props.isCompany) {
      return (RenderCompanySidebar());
    } else {
      return (RenderUserSidebar());
    }
  }

  return (
    <SidebarForm>
      <List className="List">
        {ChooseCompanyOrUser()}
      </List>
    </SidebarForm>
  );
}
export default Sidebar;
