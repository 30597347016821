import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import mainTheme from './styles/mainTheme.js';

import { MuiThemeProvider } from '@material-ui/core/styles';


// A function that routes the user to the right place
// after login
// const onRedirectCallback = appState => {
//   window.history.replaceState(
//     {},
//     document.title,
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   );
// };

ReactDOM.render(

    <MuiThemeProvider theme = { mainTheme }>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MuiThemeProvider>
    ,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
