//Loading.js
///////////////////////////////
//React & Material
import React from 'react';
import PropTypes from 'prop-types';

//Plugins
import styled, {css} from 'styled-components';
import { motion } from "framer-motion";
//Theme (required for styleguidist workaround)

import {
  Typography,
}from '@material-ui/core';

//////////////////////////////

const LeftCircle = styled(motion.div)`
    height:50px;
    width:50px;
    border-radius:50px;
    background-color:#FF9703;
    z-index:1;
`
const RightCircle = styled(motion.div) `
    height:50px;
    width:50px;
    border-radius:50px;
    background-color:#ffffff;
    position: relative;
    top: -50px;
    z-index:0;
`

const LoaderWrapper = styled.div `
  margin-bottom:50px;
    ${props =>
    props.debugContrastBg && css `
      background-color:black;
      padding:100px 200px;
    `};

`

const MainWrapper = styled.div`
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
`

//////////////////////////////
//Component class
function Loading(props) {

  Loading.propTypes = {
    /** makes the bg black for styleguidist */
    debugContrastBg: PropTypes.bool,
    /** Sets the text under the component */
    displayText: PropTypes.string,
  }

  Loading.defaultProps = {
    debugContrastBg: false,
  };

  return (
    <MainWrapper>
     <LoaderWrapper debugContrastBg={props.debugContrastBg}>
        <LeftCircle
        animate={{
            x: [0,70,100,30,0],
            y: [0,20,0,-20,0],
            scale: [1.2,1.1,1,0.9,1.2],
            backgroundColor: ["#FF9703","#fff","#FF9703","#fff","#FF9703"],
        }}
        transition={{
            duration: 2.5,
            ease: "backInOut",
            loop: Infinity,
        }}>
        </LeftCircle>
        <RightCircle
         animate={{
            x: [0,-70,-100,-30,0],
            y: [0,-20,0,20,0],
            scale: [1,0.9,1,1.1,1],
            backgroundColor: ["#fff","#fff","#FF9703","#fff","#FF9703"],
        }}
        transition={{
            duration: 2.5,
            ease: "backInOut",
            loop: Infinity,
        }}>
        </RightCircle>
      </LoaderWrapper>
      <Typography variant="subtitle1">{props.displayText}</Typography>
    </MainWrapper>
  )
}
export default Loading;