//Contact.js
///////////////////////////////
//React & Material
import React from "react";
import {
  Typography,
  TextField,
  Button,
  Hidden,
  Grid,
} from "@material-ui/core";

//Plugins
import { motion } from "framer-motion"
import { Formik } from "formik";
import * as Yup from "yup";
import styled, { css } from "styled-components";

import axios from "axios";

//Utils
import { useNotificationHelper } from '../../../Utils/NotificationHelper'


//Image Imports
import DecorationOne from "./DecorationOne.svg"
import LeftVector from './LeftVector'

//////////////////////////////
//Styled components
const FormWrapper = styled.form`
  margin-top: 30px;
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

//A button with gradient background
const GradientButton = styled(Button)`
  background: linear-gradient(45deg, #fc9511 30%, #d22600 90%);
  color: #FFF!important;
  &:hover {
    background: #e65c00;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F9D423, #e65c00);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F9D423, #e65c00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.3);
  }

  @media only screen and (max-width: 600px) {
    width:100%;
  }
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width:fit-content;
  @media only screen and (min-width: 600px) {
    > div:nth-child(1) {
    margin-right: 10px;
  }
  > div:nth-child(2) {
    margin-left: 10px;
  }
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  @media only screen and (max-width : 600px) {
    width:auto!important;
  }
`

const ContactWrapper = styled.div`
  ${props =>
    props.debugContrastBg &&
    css`
       background-color: black;
    `}
    ${props =>
    !props.debugContrastBg &&
    css`
      width:100vw;
    `}
  display:flex;
  flex-direction: column;
  margin-top:20px;
  padding: 0 10px;
  margin-bottom: 30px;
`

const ButtonWrapper = styled.div`
  margin-top:20px;
  @media only screen and (max-width: 600px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }
`

const MessageWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
`

//////////////////////////////
//Contact Component Function
/**
 * Displays a contact form for the user.
 * Validates first name, last name and email.
 * Sends form data to flask server
 */
function ContactForm(props) {


  const { showNotification } = useNotificationHelper()

  //direction of where the slide in animation should come from
  const direction = 1;

  //variants for customizing the components to slide in during load
  const variants = {
    enter: () => ({
      // x: direction > 0 ? 350 : -350,
      x:0,
      opacity: 1,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
  };

  const NoStyle = styled.a`
    text-decoration: none;
    color: #FFF;
    font-weight: bold;
  `

  //Hover animation for a field
  const HoverAnimation = ({ children }) =>
    <motion.div whileHover={{ scale: 1.02 }}>
      {children}
    </motion.div>;

  //Slide in animation for rows
  const SliderAnimation = ({ children, delay }) =>
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      custom={direction}
      transition={{
        x: { duration: 0.3, type: "tween" },
        opacity: { duration: 0.25 },
        delay: delay,
      }}
    >
      {children}
    </motion.div>;

  return (
    <ContactWrapper
      debugContrastBg={props.debugContrastBg}
      data-test="contact-background"
    >
      <Grid container justify="center">
        <Hidden smDown>
          <LeftVector withDecoration decoLink={DecorationOne} imgLink={props.imgLink} />
        </Hidden>
        <Grid item xs={10} sm={8} md={6} lg={5} xl={4} >
          <SliderAnimation delay={0}>
            <Typography variant="h3" data-test="contact-header">
              Receive your quote<br/> within 24 hours
          </Typography>
          <br/>
          <br/>
          <Typography variant="body1" data-test="contact-header">
              Alternatively, email <NoStyle href="mailto:matt@mbm.space" target="_blank" >matt@mbm.space</NoStyle> for an obligation free consultation <br/>
          </Typography>
          </SliderAnimation>
          <Formik
            data-test="contact-formik"
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              companyName: "",
              message: ""
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              axios.post('/api/contact', values)
              .then(function (response) {
                showNotification("Success! Thank you for getting in touch", "success")
                  // Display success message sent
                  setTimeout(function () {
                    setSubmitting(false);
                    resetForm();
                  }, 3000);
              })
              .catch(function (error) {
                showNotification("An error occurred. Your message was not delivered.", "error")
                  setTimeout(function () {
                    setSubmitting(false);
                  }, 3000);
              });


              // flaskPost('/misc/action/contact-form', values)
              //   .then(resp => {
              //     if (resp.status === 200) {
              //       //success
              //       showNotification("Success! Thank you for getting in touch!", "success")
              //     }
              //     // Display success message sent
              //     setTimeout(function () {
              //       setSubmitting(false);
              //       resetForm();
              //     }, 3000);
              //   }).catch(error => {
              //     // Display error not sent
              //     showNotification("An error occured. Your message was not delivered.", "error")
              //     setTimeout(function () {
              //       setSubmitting(false);
              //     }, 3000);
              //   });
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be valid")
                .required("Required"),
              firstName: Yup.string()
                .min(2, "Too short")
                .max(70, "Too long")
                .required("Required"),
              lastName: Yup.string()
                .min(2, "Too short")
                .max(70, "Too long")
                .required("Required")
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
              } = props;
              return (
                <FormWrapper onSubmit={handleSubmit} data-test="from-wrapper">
                  <FieldsWrapper>
                    <SliderAnimation delay={0.3}>
                      <RowWrapper>
                        <HoverAnimation>
                          <TextField
                            variant="outlined"
                            data-test="contact-first-name"
                            error={errors.firstName && touched.firstName}
                            label="First Name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (errors.firstName &&
                                touched.firstName &&
                                errors.firstName) ||
                              " "
                            }
                            fullWidth
                            margin="dense"
                          />
                        </HoverAnimation>
                        <HoverAnimation>
                          <TextField
                            variant="outlined"
                            data-test="contact-last-name"
                            error={errors.lastName && touched.lastName}
                            label="Last Name"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (errors.lastName &&
                                touched.lastName &&
                                errors.lastName) ||
                              " "
                            }
                            fullWidth
                            margin="dense"
                          />
                        </HoverAnimation>
                      </RowWrapper>
                    </SliderAnimation>
                    <SliderAnimation delay={0.6}>
                      <RowWrapper>
                        <HoverAnimation>
                          <TextField
                            variant="outlined"
                            data-test="contact-email"
                            error={errors.email && touched.email}
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                              (errors.email && touched.email && errors.email) || " "
                            }
                            fullWidth
                            margin="dense"
                          />
                        </HoverAnimation>
                        <HoverAnimation>
                          <TextField
                            variant="outlined"
                            data-test="contact-company"
                            label="Company"
                            name="companyName"
                            value={values.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            margin="dense"
                            fullWidth
                          />
                        </HoverAnimation>
                      </RowWrapper>
                    </SliderAnimation>
                    <SliderAnimation delay={0.7}>
                      <MessageWrapper>
                        <TextField
                          variant="outlined"
                          data-test="contact-message"
                          label="Message"
                          value={values.message}
                          name="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          margin="dense"
                          fullWidth
                          rows="2"
                          rowsMax="6"
                          multiline
                        />
                      </MessageWrapper>
                    </SliderAnimation>
                    <SliderAnimation delay={0.8}>
                      <ButtonWrapper>
                        <GradientButton
                          data-test="contact-submit"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting || !isValid}
                        >
                          Submit
                              </GradientButton>
                      </ButtonWrapper>
                    </SliderAnimation>
                  </FieldsWrapper>
                </FormWrapper>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </ContactWrapper>
  )
}
export default ContactForm;